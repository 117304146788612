import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { createPageViewData } from "../utils/tealium";
import Fixtures from "./Fixtures";
import { Main, Wrapper } from "./Layout";

const FixturesWrapper: React.FC<RouteComponentProps<{ eventId?: string }>> = ({
  eventId = 0,
}) => {
  useEffect(() => {
    createPageViewData("schedule");
  }, []);

  return (
    <Wrapper>
      <Main>
        <Fixtures eventId={Number(eventId)} useLinks />
      </Main>
    </Wrapper>
  );
};

export default FixturesWrapper;
