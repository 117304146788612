import FdjLogo1x from "../img/fdj-logo-only@1x.png";
import FdjLogo2x from "../img/fdj-logo-only@2x.png";
import FdjHeader1x from "../img/fdj-logo@1x.png";
import FdjHeader2x from "../img/fdj-logo@2x.png";
import FdrPageAdSmall from "../img/fdr-page-ad-image-384.png";
import FdrPageAdLarge from "../img/fdr-page-ad-image-768.png";
import SportingBetLogo1x from "../img/sportingbet-logo-only@1x.png";
import SportingBetLogo2x from "../img/sportingbet-logo-only@2x.png";
import SportingBetHeader1x from "../img/sportingbet-logo@1x.png";
import SportingBetHeader2x from "../img/sportingbet-logo@2x.png";
import SportingBetPageAdSmall from "../img/sportingbet-page-ad-image-384.png";
import SportingBetPageAdLarge from "../img/sportingbet-page-ad-image-768.png";

type SiteAdData = Record<
  string,
  { href?: string; srcSetSmall: string; srcSetLarge: string; altText: string }
>;

// SITE_IDS represents the valid keys of the sitesAdData obj.
export type SITE_IDS = "main_fr" | "main_br";

// Checks if the site string is a valid key in sitesAdData obj.
export const isSiteWithAd = (
  site: string,
  sitesAdData: SiteAdData
): site is SITE_IDS => {
  return site in sitesAdData;
};

export const PageAdData = {
  main_fr: {
    href: "https://ad.doubleclick.net/ddm/trackclk/N30402.2403113NBA/B25332343.407727817;dc_trk_aid=599854830;dc_trk_cid=225034333;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1",
    srcSetSmall: FdrPageAdSmall,
    srcSetLarge: FdrPageAdLarge,
    altText: "Présenté Par Parions Sport en ligne",
  },
  main_br: {
    href: "https://sports.sportingbet.bet.br/?wm=5507762&utm_source=app-nba-voa&utm_campaign=linkdabioappnba2025&utm_content={{ad.id}}&utm_medium=appnba-{{campaign.id}}_{{adset.id}}_{{ad.id}}&utm_term=5507762-linkdabioappnba2025-sptbet-sprts-br-2025-1-2-pt-app--acq-app&tdpeh=fb-{{ad.id}}{{site_source_name}}{{placement}}",
    srcSetSmall: SportingBetPageAdSmall,
    srcSetLarge: SportingBetPageAdLarge,
    altText: "Sporting Bet",
  },
};

export const HeaderAdData = {
  main_fr: {
    href: "https://ad.doubleclick.net/ddm/trackclk/N30402.2403113NBA/B25332343.407727289;dc_trk_aid=599854809;dc_trk_cid=225077246;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1",
    srcSetSmall: FdjHeader1x,
    srcSetLarge: FdjHeader2x,
    altText: "Présenté Par Parions Sport en ligne",
  },
  main_br: {
    href: "https://sports.sportingbet.bet.br/?wm=5507762&utm_source=app-nba-voa&utm_campaign=linkdabioappnba2025&utm_content={{ad.id}}&utm_medium=appnba-{{campaign.id}}_{{adset.id}}_{{ad.id}}&utm_term=5507762-linkdabioappnba2025-sptbet-sprts-br-2025-1-2-pt-app--acq-app&tdpeh=fb-{{ad.id}}{{site_source_name}}{{placement}",
    srcSetSmall: SportingBetHeader1x,
    srcSetLarge: SportingBetHeader2x,
    altText: "Sporting Bet",
  },
};

export const TopScorersAdData = {
  main_fr: {
    srcSetSmall: FdjLogo1x,
    srcSetLarge: FdjLogo2x,
    altText: "Présenté Par Parions Sport en ligne",
  },
  main_br: {
    srcSetSmall: SportingBetLogo1x,
    srcSetLarge: SportingBetLogo2x,
    altText: "Sporting Bet",
  },
};
