import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElements } from "../../core/store/elements/reducers";
import { IElement } from "../../core/store/elements/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { getPlayerData } from "../../core/store/player/reducers";
import { getTeamsById } from "../../core/store/teams/reducers";
import Title from "../Title";
import EventInfo from "./EventInfo";
import GameweekScheduleTracker from "./GameweekScheduleTracker";
import InjuryTracker from "./InjuryTracker";
import PageAd from "./PageAd";
import TopScorers from "./TopScorers";
import TransfersPanel from "./TransfersPanel";

const StatusWrapper = styled.div`
  max-width: 1220px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[2]};
  }
`;

const StatusCols = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
    gap: 10rem;
  }
`;

const Status: React.FC = () => {
  const { t } = useTranslation();
  const now = useSelector(getCurrentEvent);
  const player = useSelector((state: RootState) => getPlayerData(state));
  const elements = useSelector((state: RootState) => getElements(state));
  const teamsById = useSelector(getTeamsById);

  if (!player) {
    return null;
  }

  const groupedElements: { front_court: IElement[]; back_court: IElement[] } = {
    front_court: [],
    back_court: [],
  };

  Object.values(elements).forEach((element: IElement) => {
    if (element.element_type === 2) {
      groupedElements.front_court.push(element);
    } else if (element.element_type === 1) {
      groupedElements.back_court.push(element);
    }
  });

  return (
    <StatusWrapper>
      <Box mt={4} mb={2} px={2}>
        {now && (
          <Title>
            {t("status.titleN", "{{name}} Status", { name: `${now.name}` })}
          </Title>
        )}
      </Box>
      {now && (
        <Box mb={8}>
          <EventInfo />
        </Box>
      )}
      <PageAd />
      {now && <TopScorers elements={elements} />}
      <Box mb={8}>
        <GameweekScheduleTracker />
      </Box>
      {now && (
        <StatusCols>
          <Box mb={8}>
            <TransfersPanel />
          </Box>
          <Box mb={8}>
            <TransfersPanel isOut={true} />
          </Box>
        </StatusCols>
      )}
      <Box mb={8}>
        <InjuryTracker
          newsCount={5}
          showLink={true}
          borderBottom={true}
          teamsById={teamsById}
          elements={elements}
        />
      </Box>
    </StatusWrapper>
  );
};

export default Status;
