import { RouteComponentProps } from "@reach/router";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { updateElementControls } from "../../core/store/elements/actions";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import {
  showElementSummary,
  updateElementControlsAndMaxCost,
} from "../../core/store/elements/thunks";
import {
  IElement,
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney } from "../../core/utils/money";
import i18n from "../../i18n";
import { integerToDecimal, isDecimalStat } from "../../utils/decimal";
import { getStatDetails } from "../../utils/stats";
import { createPageViewData } from "../../utils/tealium";
import Alert from "../Alert";
import ElementFilter from "../element-controls/ElementFilter";
import ElementSort from "../element-controls/ElementSort";
import Paginator, { paginate } from "../element-controls/Paginator";
import ElementDialogButton from "../ElementDialogButton";
import { ElementRow, ElementTable } from "../ElementTable";
import { FieldCol, FieldCols } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import Media from "../Media";
import Shirt from "../Shirt";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { Ellipsis, VisuallyHidden } from "../Utils";

const StatsStatus = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 7%;
  }
`;

const StatsElement = styled.th`
  width: 40%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 38%;
  }
`;

const StatsCost = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsSel = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsForm = styled.th`
  width: 10%;
  white-space: normal;
  overflow-wrap: break-word;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsPts = styled.th`
  width: 10%;
  white-space: normal;
  overflow-wrap: break-word;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsExtra = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const ElementNameButton = styled.button`
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
`;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    text-decoration: underline;
  }
`;

const Team = styled.div`
  margin-inline-end: ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  ${i18n.language === "tr" &&
  css`
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  `}
`;

interface IState {
  page: number;
}

type OwnProps = RouteComponentProps<{ statName?: string }>;

interface IPropsFromState {
  currencyDivisor: number;
  elements: IElementDataFromControls;
  controls: IElementControls;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type Props = OwnProps & WithTranslation & IPropsFromState & IPropsFromDispatch;

class Statistics extends React.Component<Props, IState> {
  public state: IState = { page: 1 };

  public handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      filter: e.target.value,
    });
  };

  public handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      sort: e.target.value,
    });
  };

  public setPage = (page: number) => this.setState({ page });

  public defaultStats: Array<keyof IElement> = [
    "now_cost",
    "selected_by_percent",
    "form",
    "total_points",
  ];

  public componentDidMount() {
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: "all",
      sort: this.props.statName || "total_points",
      search: "",
    });
    createPageViewData("statistics");
  }

  public render() {
    const {
      controls,
      currencyDivisor,
      elements,
      showElementDialog,
      teamsById,
      t,
    } = this.props;
    const { page } = this.state;
    const { data, totalPages } = paginate(elements.data, page, 30);
    const extraStat =
      this.defaultStats.indexOf(controls.sort) === -1 ? controls.sort : "";
    const statDetail = getStatDetails(String(controls.sort), this.props.t);

    return (
      <Wrapper>
        <Main>
          <VisuallyHidden as="div">
            <h2>{t("statistics.title", "Statistics")}</h2>
          </VisuallyHidden>
          <form>
            <FieldCols>
              <FieldCol>
                <ElementFilter handleFilterChange={this.handleFilterChange} />
              </FieldCol>
              <FieldCol>
                <ElementSort handleSortChange={this.handleSortChange} />
              </FieldCol>
            </FieldCols>
          </form>
          <Box mb="4">
            <Alert type="info" textAlign="start">
              {statDetail ? statDetail.description : ""}
            </Alert>
          </Box>
          <ScrollTable>
            <ElementTable>
              <thead>
                <tr>
                  <StatsStatus>&nbsp;</StatsStatus>
                  <StatsElement>
                    {t("statistics.player", "Player")}
                  </StatsElement>
                  <StatsCost>$</StatsCost>
                  <StatsSel>
                    <Tooltip
                      content={t("statistics.selectedByLong", "Selected by %")}
                    >
                      <TooltipLabel>
                        {t("statistics.selectedByShort", "Sel.")}
                      </TooltipLabel>
                    </Tooltip>
                  </StatsSel>
                  <StatsForm>{t("statistics.form", "Form")}</StatsForm>
                  <StatsPts>
                    <Tooltip
                      content={t("statistics.totalPoints", "Total points")}
                    >
                      <TooltipLabel>
                        {t("statistics.totalPointsShort", "TP.")}
                      </TooltipLabel>
                    </Tooltip>
                  </StatsPts>
                  {extraStat ? <StatsExtra>**</StatsExtra> : null}
                </tr>
              </thead>
              <tbody>
                {data.map((e) => (
                  <ElementRow key={e.id}>
                    <td>
                      <ElementDialogButton elementId={e.id} variant="list" />
                    </td>
                    <td>
                      <ElementNameButton
                        onClick={() => showElementDialog(e.id)}
                      >
                        <Media
                          img={<Shirt elementId={e.id} width="50px" />}
                          centred={true}
                        >
                          <Name>
                            <Ellipsis>
                              {e.first_name} {e.second_name}
                            </Ellipsis>
                          </Name>
                          <Team>{teamsById[e.team].short_name}</Team>
                        </Media>
                      </ElementNameButton>
                    </td>
                    <td>{integerToMoney(e.now_cost, currencyDivisor)}</td>
                    <td>{e.selected_by_percent}%</td>
                    <td>{integerToDecimal(Number(e.form), 10)}</td>
                    <td>{integerToDecimal(e.total_points, 10)}</td>
                    {extraStat ? (
                      <td>
                        {isDecimalStat(extraStat)
                          ? integerToDecimal(e[extraStat] as number, 10)
                          : e[extraStat]}
                      </td>
                    ) : null}
                  </ElementRow>
                ))}
              </tbody>
            </ElementTable>
          </ScrollTable>
          <Paginator
            totalPages={totalPages}
            page={page}
            setPage={this.setPage}
          />
        </Main>
      </Wrapper>
    );
  }
}

export { Statistics as StatisticsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  currencyDivisor: 10,
  elements: getElementsFromControls(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Statistics)
);
