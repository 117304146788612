import { navigate, RouteComponentProps } from "@reach/router";
import range from "lodash/range";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import moment from "moment";
import { size } from "polished";
import React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { bootstrap } from "../../core/store/bootstrap/thunks";
import {
  getPlayerData,
  getPlayerUpdateError,
  getRegisterError,
} from "../../core/store/player/reducers";
import { playerUpdate, register } from "../../core/store/player/thunks";
import { getRegions } from "../../core/store/regions/reducers";
import { fetchRegions } from "../../core/store/regions/thunks";
import { IRegion } from "../../core/store/regions/types";
import { ReactComponent as NbaIDLogo } from "../../img/nba-id-logo.svg";
import { IPlayer } from "../../types";
import { getSite } from "../../utils/sites";
import {
  createInteractionData,
  createCustomInteractionData,
} from "../../utils/tealium";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import {
  CheckboxField,
  FieldFeedback,
  InputField,
  SelectField,
} from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";

const SaveBar = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]};
  padding: 0 20% ${(props) => props.theme.space[4]};
  border-bottom: 3px solid #bfbfbf;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 10%;
    margin-left: 10%;
  }
`;

const DOBFieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
`;

const DOBFieldsetInner = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.space[2]};
    margin-left: -${({ theme }) => theme.space[2]};
    margin-right: -${({ theme }) => theme.space[2]};
  }
`;

const DOBLegend = styled.legend`
  display: block;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.space[1]};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
`;

const DOBCol = styled.div`
  margin-top: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: calc(100% / 3);
    margin-top: 0;
    padding-left: ${({ theme }) => theme.space[2]};
    padding-right: ${({ theme }) => theme.space[2]};
  }
`;

const TitleStyle = styled.h2`
  margin-top: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[3]};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 4rem;
  font-style: italic;
  text-transform: uppercase;
`;

const StyledNBAIdLogo = styled(NbaIDLogo)`
  ${size(45)};
`;

interface IPropsFromState {
  player: IPlayer | null;
  regions: IRegion[];
  registerError: IError | null;
  updateError: IError | null;
}

interface IPropsFromDispatch {
  doBootstrap: () => Promise<void>;
  registerPlayer: (data: {}) => Promise<void>;
  updatePlayer: (data: {}) => Promise<void>;
  fetchRegionData: () => void;
}

type Props = RouteComponentProps &
  WithTranslation &
  IPropsFromState &
  IPropsFromDispatch;

interface IState {
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  errorEmail: string;
  errorFName: string;
  errorLName: string;
  errorPass: string;
  region: string;
  emailNba: boolean;
  privacyNba: boolean;
  errorRegion: string;
  formDisabled: boolean;
  errorDob: string;
}

class Register extends React.Component<Props, IState> {
  public errorsTxt: { [key: string]: string } = {
    EDob: this.props.t("register.errors.dobn", "Date of birth not valid"),
    EEmail: this.props.t("register.errors.email", "An email is required"),
    EFName: this.props.t("register.errors.fname", "First name is required"),
    ELName: this.props.t("register.errors.lname", "Last name is required"),
    EPass: this.props.t("register.errors.pass", "A password is required"),
    EPassLength: this.props.t(
      "register.errors.passLength",
      "Password must be at least 8 characters long and include 1 capital letter and 1 symbol or number"
    ),
    EValidEmail: this.props.t(
      "register.errors.validEmail",
      "This Email address is already registered"
    ),
    EValidPass: this.props.t("register.errors.validPass", "Password not valid"),
    ERegion: this.props.t("register.errors.region", "A region is required"),
    EInvalid: this.props.t(
      "register.errors.invalid",
      "There was an error with your registration. If you already have an account, please try to log in"
    ),
    EInvalidDob: this.props.t(
      "register.errors.invalidDob",
      "The game is only available to individuals aged 16 and over"
    ),
    EInvalidEmail: this.props.t(
      "register.errors.invalidEmail",
      "Email address is already taken. If you already have an account, please try to log in"
    ),
    EInvalidPassword: this.props.t(
      "register.errors.invalidPassword",
      "Password must be at least 8 characters long and include 1 capital letter and 1 symbol or number."
    ),
    EInvalidFname: this.props.t(
      "register.errors.invalidFname",
      "First Name must be provided"
    ),
    EInvalidLname: this.props.t(
      "register.errors.invalidLname",
      "Last Name must be provided"
    ),
    EInvalidRegion: this.props.t(
      "register.errors.invalidRegion",
      "The region selected is invalid"
    ),
    EInvalidEconsent: this.props.t(
      "register.errors.invalidEconsent",
      "NBA Email consent invalid"
    ),
    EInvalidPconsent: this.props.t(
      "register.errors.invalidPconsent",
      "NBA Privacy consent invalid"
    ),
  };

  public months: { [key: string]: string } = {
    1: this.props.t("months.janShort", "Jan"),
    2: this.props.t("months.febShort", "Feb"),
    3: this.props.t("months.marShort", "Mar"),
    4: this.props.t("months.aprShort", "Apr"),
    5: this.props.t("months.mayShort", "May"),
    6: this.props.t("months.junShort", "Jun"),
    7: this.props.t("months.julShort", "Jul"),
    8: this.props.t("months.augShort", "Aug"),
    9: this.props.t("months.sepShort", "Sep"),
    10: this.props.t("months.octShort", "Oct"),
    11: this.props.t("months.novShort", "Nov"),
    12: this.props.t("months.decShort", "Dec"),
  };

  constructor(props: Props) {
    super(props);
    const player = props.player;
    this.state = {
      birthDay:
        player && player.date_of_birth
          ? Number(player.date_of_birth.split("-")[2])
          : 0,
      birthMonth:
        player && player.date_of_birth
          ? Number(player.date_of_birth.split("-")[1])
          : 0,
      birthYear:
        player && player.date_of_birth
          ? Number(player.date_of_birth.split("-")[0])
          : 0,
      email:
        player && player.email.indexOf("@ismfgsocial") === -1
          ? player.email
          : "",
      errorDob: "",
      errorEmail: "",
      errorFName: "",
      errorLName: "",
      errorPass: "",
      firstName: player ? player.first_name : "",
      lastName: player ? player.last_name : "",
      password: "",
      emailNba: player && player.nba_email ? true : false,
      privacyNba: player ? true : false,
      errorRegion: "",
      region: props.player ? props.player.region : "",
      formDisabled: false,
    };
  }

  public getMode = () => {
    if (!this.props.player) {
      return "register";
    }
    return this.props.player.dirty ? "confirm" : "update";
  };

  public getRequiredFields(): Array<keyof IState> {
    switch (this.getMode()) {
      case "register":
        return [
          "birthDay",
          "birthMonth",
          "birthYear",
          "email",
          "firstName",
          "lastName",
          "password",
          "region",
          "privacyNba",
        ];
      case "confirm":
        return ["firstName", "lastName"];
      case "update":
        return [];
    }
  }

  public apiDataFromState() {
    switch (this.getMode()) {
      case "register":
        return {
          date_of_birth: `${this.state.birthYear}-${this.state.birthMonth}-${this.state.birthDay}`,
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          region: this.state.region,
          nba_email: this.state.emailNba ? "1" : "0",
          nba_privacy: this.state.privacyNba ? "1" : "0",
        };
      case "confirm":
        return {
          date_of_birth: `${this.state.birthYear}-${this.state.birthMonth}-${this.state.birthDay}`,
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          region: this.state.region,
          nba_email: this.state.emailNba,
          nba_privacy: this.state.privacyNba,
        };
      case "update":
        return {};
    }
  }

  public handleBirthDayChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ birthDay: parseInt(e.currentTarget.value, 10) });

  public handleBirthMonthChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ birthMonth: parseInt(e.currentTarget.value, 10) });

  public handleBirthYearChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ birthYear: parseInt(e.currentTarget.value, 10) });

  public handleEmailChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ email: e.currentTarget.value, formDisabled: false });

  public handleFirstNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ firstName: e.currentTarget.value });

  public handleLastNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ lastName: e.currentTarget.value });

  public handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ password: e.currentTarget.value, formDisabled: false });

  public handleRegionChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ region: e.currentTarget.value });

  public handleEmailNbaChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ emailNba: e.currentTarget.checked ? true : false });

  public handleNbaPrivacyChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ privacyNba: e.currentTarget.checked ? true : false });

  public handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const mode = this.getMode();
    this.setState({ formDisabled: true });
    if (mode === "register") {
      try {
        createInteractionData({
          contentTitle: "home",
          interactionId: "nba-fantasy-game:sign-up:cta",
          interactionText: this.props.t("register.label.register", "Register"),
        });
        await this.props.registerPlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        const errors = this.handleServerErrors();
        createCustomInteractionData({
          contentTitle: "home",
          interactionId: "fantasy:sign-up:error",
          ampEventName: "User Account: Registration Error",
          errorDetail: values(errors)
            .map((error) => error.errorDetail)
            .join(", "),
        });
        return;
      }
      await this.props.doBootstrap();
      const userData = {
        userId: this.props.player?.nbaciamguid,
        userState: "authenticated",
      };
      createCustomInteractionData({
        contentTitle: "home",
        interactionId: "fantasy:sign-up:success",
        userData: userData,
        ampEventName: "User Account: Registration Success",
      });
      navigate("/squad-selection");
    } else if (mode === "confirm") {
      try {
        await this.props.updatePlayer(this.apiDataFromState());
      } catch (e) {
        window.scrollTo(0, 0); // To see error if failure ...
        return;
      }
      await this.props.doBootstrap();
      navigate("/squad-selection");
    } else if (mode === "update") {
      await this.props.doBootstrap();
      // Maybe this should be back to my team, maybe our LoggedIn component
      navigate("/squad-selection");
    }
    this.setState({ formDisabled: false });
  };

  public componentDidMount() {
    this.props.fetchRegionData();
  }

  public getIntRegions() {
    const regs = this.props.regions;
    return sortBy(regs, ["name"]);
  }

  public handleFirstnameBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorFName: this.errorsTxt.EFName });
    } else {
      this.setState({ errorFName: "" });
    }
  };
  public validatePassword(pw: string) {
    return (
      /[A-Z]/.test(pw) &&
      (/[0-9]/.test(pw) || /[^A-Za-z0-9]/.test(pw)) &&
      pw.length > 7
    );
  }

  public handleLastnameBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorLName: this.errorsTxt.ELName });
    } else {
      this.setState({ errorLName: "" });
    }
  };
  public handlePasswordBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorPass: this.errorsTxt.EPass });
    } else {
      if (!this.validatePassword(e.currentTarget.value)) {
        this.setState({ errorPass: this.errorsTxt.EPassLength });
      } else {
        this.setState({ errorPass: "" });
      }
    }
  };

  public handleEmailBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      this.setState({ errorEmail: this.errorsTxt.EEmail });
    } else {
      this.setState({ errorEmail: "" });
    }
  };

  public handleRegionBlur = (e: React.FormEvent<HTMLSelectElement>) => {
    if (!e.currentTarget.value || e.currentTarget.value === "0") {
      this.setState({ errorRegion: this.errorsTxt.ERegion });
    } else {
      this.setState({ errorRegion: "" });
    }
  };
  public ageLimit() {
    const site = getSite();
    interface Dictionary {
      [key: string]: number;
    }
    const ageLimitDefaults: Dictionary = {
      default: 16,
      main_ar: 16,
      main_he: 16,
      main_tr: 16,
      main_en: 18,
      main_fr: 18,
      main_de: 18,
      main_es: 18,
      main_it: 18,
      main_el: 18,
      main_br: 18,
    };
    const ageLimit =
      site in ageLimitDefaults
        ? ageLimitDefaults[site.toLowerCase()]
        : ageLimitDefaults["default"];
    return ageLimit;
  }

  public handleDobBlur = (e: React.FormEvent<HTMLSelectElement>) => {
    const year = this.state.birthYear;
    const month = this.state.birthMonth;
    const day = this.state.birthDay;
    const ageLim = this.ageLimit();
    if (year > 0 && month > 0 && day > 0) {
      if (moment(new Date(year, month - 1, day)).isValid()) {
        const today = new Date();
        const birthDate = new Date(year, month - 1, day);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < ageLim) {
          this.setState({ errorDob: this.errorsTxt.EDob });
          this.setState({ formDisabled: true });
        } else {
          this.setState({ errorDob: "" });
          this.setState({ formDisabled: false });
        }
      } else {
        this.setState({ errorDob: this.errorsTxt.EDob });
        this.setState({ formDisabled: true });
      }
    } else {
      this.setState({ formDisabled: true });
    }
  };

  public handleServerErrors = () => {
    const errors =
      this.getMode() === "register"
        ? this.props.registerError
        : this.props.updateError;
    const formattedErrors = [];
    if (errors) {
      if (
        errors.badRequest &&
        errors.badRequest.password &&
        errors.badRequest.password[0].code === "invalid"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EValidPass,
          errorDetail: "password|Password not valid",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.email &&
        errors.badRequest.email[0].code === "duplicate"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EValidEmail,
          errorDetail: "email|Email already registered",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.email &&
        errors.badRequest.email[0].code === "blank"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EEmail,
          errorDetail: "email|Email is required",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.password &&
        errors.badRequest.password[0].code === "blank"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EPass,
          errorDetail: "password|Password is required",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.first_name &&
        errors.badRequest.first_name[0].code === "blank"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EFName,
          errorDetail: "first name|First name is required",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.last_name &&
        errors.badRequest.last_name[0].code === "blank"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.ELName,
          errorDetail: "last name|Last name is required",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.region &&
        errors.badRequest.region[0].code === "does_not_exist"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.ERegion,
          errorDetail: "region|Region is required",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "invalid" &&
        errors.badRequest.non_field_errors[0].message.includes("EMAIL_TAKEN")
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidEmail,
          errorDetail: "email address|Email address is already taken",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "invalid" &&
        errors.badRequest.non_field_errors[0].message.includes(
          "INVALID_PASSWORD"
        )
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidPassword,
          errorDetail: "password|Incorrect format",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "invalid" &&
        errors.badRequest.non_field_errors[0].message.includes(
          "INVALID_FIRST_NAME"
        )
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidFname,
          errorDetail: "first name|First name must be provided",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "invalid" &&
        errors.badRequest.non_field_errors[0].message.includes(
          "INVALID_LAST_NAME"
        )
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidLname,
          errorDetail: "last name|Last name must be provided",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "invalid" &&
        errors.badRequest.non_field_errors[0].message.includes(
          "INVALID_COUNTRY"
        )
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidRegion,
          errorDetail: "region|Region selected is invalid",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "invalid" &&
        errors.badRequest.non_field_errors[0].message.includes(
          "INVALID_EMAIL_CONSENT"
        )
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidEconsent,
          errorDetail: "consent|NBA Email consent invalid",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "invalid" &&
        errors.badRequest.non_field_errors[0].message.includes(
          "INVALID_PRIVACY_CONSENT"
        )
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidPconsent,
          errorDetail: "consent|NBA Privacy consent invalid",
        });
      }
      if (
        errors.badRequest &&
        errors.badRequest.non_field_errors &&
        errors.badRequest.non_field_errors[0].code === "under_age"
      ) {
        formattedErrors.push({
          errorText: this.errorsTxt.EInvalidDob,
          errorDetail: "date of birth|Underage",
        });
      }

      return formattedErrors;
    }
  };

  public isFormDisabled = () => {
    const requiredFieldsMissing = this.getRequiredFields().some(
      (e) => !this.state[e]
    );
    return requiredFieldsMissing || this.state.formDisabled;
  };

  public render() {
    const { t } = this.props;
    const mode = this.getMode();
    const title = {
      register: t(
        "register.title.registration",
        "Register your NBA fantasy account"
      ),
      confirm: t("register.title.confirm", "Confirm your details"),
      update: t("register.title.updaten", "Update your details"),
    };
    const buttonText = {
      register: t("register.label.register", "Register"),
      confirm: t("register.label.confirm", "Update"),
      update: t("register.label.update", "Update"),
    };
    const age = this.ageLimit();
    const errors = this.handleServerErrors();

    return (
      <Wrapper>
        <Main>
          <div>
            {(mode === "confirm" || mode === "register") && (
              <Box mx={2}>
                <StyledNBAIdLogo />

                <TitleStyle>{title[mode]}</TitleStyle>
                <p>
                  {t(
                    "register.required",
                    "Fields marked with an asterisk (*) are required."
                  )}
                  .
                </p>
              </Box>
            )}
            {errors && (
              <Box my={4} mx={2}>
                <Alert type="error">
                  {values(errors).map((r) => r.errorText)}
                </Alert>
              </Box>
            )}
            {(mode === "confirm" || mode === "register") && (
              <Copy>
                <form onSubmit={this.handleSubmit}>
                  {mode === "register" && (
                    <Box>
                      <InputField
                        required
                        id="ismRegisterEmail"
                        type="email"
                        hasErrors={!!this.state.errorEmail}
                        label={t("register.label.email", "Email")}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        onBlur={this.handleEmailBlur}
                      />
                      {this.state.errorEmail && (
                        <FieldFeedback>{this.state.errorEmail}</FieldFeedback>
                      )}
                    </Box>
                  )}
                  {mode === "register" && (
                    <Box mt={2}>
                      <InputField
                        required
                        id="ismRegisterPassword"
                        type="password"
                        hasErrors={!!this.state.errorPass}
                        label={t("register.label.password", "Password")}
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        hint={t(
                          "register.hint.passsword",
                          "Must be at least 8 characters long and include 1 capital letter and 1 symbol or number."
                        )}
                        onBlur={this.handlePasswordBlur}
                      />
                      {this.state.errorPass && (
                        <FieldFeedback>{this.state.errorPass}</FieldFeedback>
                      )}
                    </Box>
                  )}

                  <Box mt={2}>
                    <InputField
                      required
                      id="ismRegisterFirstName"
                      hasErrors={!!this.state.errorFName}
                      label={t("register.label.fName", "First Name")}
                      value={this.state.firstName}
                      onChange={this.handleFirstNameChange}
                      onBlur={this.handleFirstnameBlur}
                    />
                    {this.state.errorFName && (
                      <FieldFeedback>{this.state.errorFName}</FieldFeedback>
                    )}
                  </Box>
                  <Box mt={2}>
                    <InputField
                      required
                      id="ismRegisterLastName"
                      label={t("register.label.lName", "Last Name")}
                      hasErrors={!!this.state.errorLName}
                      value={this.state.lastName}
                      onChange={this.handleLastNameChange}
                      onBlur={this.handleLastnameBlur}
                    />
                    {this.state.errorLName && (
                      <FieldFeedback>{this.state.errorLName}</FieldFeedback>
                    )}
                  </Box>
                  {mode === "register" && (
                    <Box mt={2}>
                      {this.state.errorRegion && (
                        <FieldFeedback>{this.state.errorRegion}</FieldFeedback>
                      )}
                      <SelectField
                        required
                        value={this.state.region}
                        onChange={this.handleRegionChange}
                        label={t("register.label.regionn", "Region")}
                        id="ismRegisterRegion"
                        onBlur={this.handleRegionBlur}
                      >
                        <option value="" aria-selected={!this.state.region}>
                          {t("register.label.select", "Select")}
                        </option>
                        {values(this.getIntRegions()).map((r) => (
                          <option
                            value={r.iso_code_short}
                            aria-selected={
                              r.iso_code_short === this.state.region
                            }
                            key={r.id}
                          >
                            {r.name}
                          </option>
                        ))}
                      </SelectField>
                    </Box>
                  )}
                  {mode === "register" && (
                    <Box mt={2}>
                      <DOBFieldset>
                        <DOBLegend>
                          {t("register.label.dob", "Date of birth")}
                        </DOBLegend>
                        <Trans i18nKey="register.hint.dob">
                          The game is only available to individuals aged{" "}
                          {{ age }} and over
                        </Trans>
                        <DOBFieldsetInner>
                          <DOBCol>
                            <SelectField
                              labelVisbility={false}
                              id="ismRegisterBirthDay"
                              value={this.state.birthDay}
                              onChange={this.handleBirthDayChange}
                              label={t("register.label.day", "Day")}
                              onBlur={this.handleDobBlur}
                            >
                              <option
                                value="0"
                                aria-selected={!this.state.birthDay}
                                disabled
                                defaultValue="0"
                              >
                                {t("register.label.day", "Day")}
                              </option>
                              {range(1, 32).map((d) => (
                                <option
                                  value={d}
                                  aria-selected={d === this.state.birthDay}
                                  key={d}
                                >
                                  {d}
                                </option>
                              ))}
                            </SelectField>
                          </DOBCol>
                          <DOBCol>
                            <SelectField
                              labelVisbility={false}
                              id="ismRegisterBirthMonth"
                              value={this.state.birthMonth}
                              onChange={this.handleBirthMonthChange}
                              label={t("register.label.month", "Month")}
                              onBlur={this.handleDobBlur}
                            >
                              <option
                                value="0"
                                aria-selected={!this.state.birthMonth}
                                disabled
                                defaultValue="0"
                              >
                                {t("register.label.month", "Month")}
                              </option>
                              {Object.keys(this.months).map((m) => (
                                <option
                                  value={m}
                                  aria-selected={
                                    parseInt(m, 10) === this.state.birthMonth
                                  }
                                  key={m}
                                >
                                  {this.months[m]}
                                </option>
                              ))}
                            </SelectField>
                          </DOBCol>
                          <DOBCol>
                            <SelectField
                              labelVisbility={false}
                              value={this.state.birthYear}
                              onChange={this.handleBirthYearChange}
                              label={t("register.label.year", "Year")}
                              id="ismRegisterBirthYear"
                              onBlur={this.handleDobBlur}
                            >
                              <option
                                value="0"
                                aria-selected={!this.state.birthYear}
                                disabled
                                defaultValue="0"
                              >
                                {t("register.label.year", "Year")}
                              </option>
                              {range(
                                new Date().getFullYear() - this.ageLimit(),
                                1900
                              ).map((y) => (
                                <option
                                  value={y}
                                  aria-selected={y === this.state.birthYear}
                                  key={y}
                                >
                                  {y}
                                </option>
                              ))}
                            </SelectField>
                          </DOBCol>
                        </DOBFieldsetInner>
                        {this.state.errorDob && (
                          <FieldFeedback>{this.state.errorDob}</FieldFeedback>
                        )}
                      </DOBFieldset>
                    </Box>
                  )}
                  {mode === "register" && (
                    <Box mt={2}>
                      <CheckboxField
                        checked={this.state.emailNba}
                        id="ismRegisterEmailNba"
                        label={t(
                          "register.label.emailNba",
                          "Please use my personal information for the NBA to send me messages and advertisements about products and initiatives of the NBA and NBA partners."
                        )}
                        onChange={this.handleEmailNbaChange}
                      />
                      <CheckboxField
                        checked={this.state.privacyNba}
                        id="ismRegisterPrivacyNba"
                        label={
                          <>
                            <Trans i18nKey="register.label.privacyNba">
                              I agree to the &nbsp;
                              <a
                                href="http://www.nba.com/news/termsofuse/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms and Conditions
                              </a>
                              &nbsp; and&nbsp;
                              <a
                                href="https://www.nba.com/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Privacy Policy
                              </a>
                            </Trans>
                            *
                          </>
                        }
                        onChange={this.handleNbaPrivacyChange}
                      />
                    </Box>
                  )}
                  <Box my={2}>
                    <SaveBar>
                      <Button
                        type="submit"
                        disabled={this.isFormDisabled()}
                        fullwidth="true"
                      >
                        {buttonText[mode]}
                      </Button>
                    </SaveBar>
                  </Box>
                </form>
              </Copy>
            )}
            {mode === "update" && (
              <Box mx={2}>
                <h2>{title[mode]}</h2>
                <p>
                  <Trans i18nKey="register.label.updateDetailsNba">
                    To update your details and password please visit &nbsp;
                    <a
                      href="https://www.nba.com/account/nbaprofile"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.nba.com/account/nbaprofile
                    </a>
                  </Trans>
                </p>
                <p>
                  {t(
                    "register.updateDetailsNba.notice",
                    "Please note, your changes will only be available in the game once you log in again"
                  )}
                </p>
              </Box>
            )}
          </div>
        </Main>
      </Wrapper>
    );
  }
}

export { Register as RegisterTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  player: getPlayerData(state) as IPlayer | null,
  regions: getRegions(state),
  registerError: getRegisterError(state),
  updateError: getPlayerUpdateError(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  doBootstrap: () => dispatch(bootstrap()),
  registerPlayer: (data) => dispatch(register(data)),
  updatePlayer: (data) => dispatch(playerUpdate(data)),
  fetchRegionData: () => dispatch(fetchRegions()),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
