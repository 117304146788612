import groupBy from "lodash/groupBy";
import range from "lodash/range";
import * as React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getElementTypes,
  getElementTypesById,
} from "../../core/store/element-types/reducers";
import {
  IElementType,
  IElementTypesById,
} from "../../core/store/element-types/types";
import { updateElementControls } from "../../core/store/elements/actions";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import {
  showElementSummary,
  updateElementControlsAndMaxCost,
  updateElementTypeControl,
} from "../../core/store/elements/thunks";
import {
  IElement,
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import {
  getProposedElementsById,
  getTypesNeeded,
} from "../../core/store/squad/reducers";
import { proposeElement, removeElement } from "../../core/store/squad/thunks";
import {
  IProposedElementsById,
  ITypesNeeded,
} from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney } from "../../core/utils/money";
import { createInteractionData } from "../../utils/tealium";
import Alert from "../Alert";
import BoldLinkButton from "../BoldLinkButton";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import ElementFilter from "../element-controls/ElementFilter";
import ElementSort from "../element-controls/ElementSort";
import Paginator from "../element-controls/Paginator";
import ElementListRow from "../ElementListRow";
import { ElementTable } from "../ElementTable";
import { FieldWrap, SearchField, SelectField } from "../FieldRenderers";
import { ControlArrowStart } from "../icons/Arrows";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { BoxWrap, PrimaryGradient } from "../Utils";

const SectionHeader = styled.div`
  ${PrimaryGradient}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: ${(props) => props.theme.radii[1]};
  border-top-right-radius: ${(props) => props.theme.radii[1]};
`;

const SectionHeading = styled.h3`
  margin: 1.5rem 1rem;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[2]};
  font-weight: 500;
  line-height: 1;
`;

const Form = styled.form`
  padding: ${(props) => `${props.theme.space[3]} ${props.theme.space[2]} 0`};
`;

const ElementsShown = styled.p`
  margin: ${({ theme }) => theme.space[2]} 0;
  text-align: center;

  strong {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ElementListElement = styled.th`
  width: 46%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 50%;
  }
`;

const ElementListPrice = styled.th`
  width: 22%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 18%;
  }
`;

const ElementListStat = styled.th`
  width: 22%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 19%;
  }
`;

const ElementTypeButton = styled.span`
  padding: 0;
  border: 0;
  background-color: transparent;
  color: currentColor;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[2]};
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
`;

const ButtonWrap = styled.div`
  margin-left: ${(props) => props.theme.space[2]};
  margin-right: ${(props) => props.theme.space[2]};
  margin-bottom: ${(props) => props.theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 33%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: none;
  }
`;

const PlayerActions = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 50%;
    margin: 0 auto;
  }
`;

interface IPropsFromState {
  controls: IElementControls;
  currencyDivisor: number;
  elementTypes: IElementType[];
  elementTypesById: IElementTypesById;
  elements: IElementDataFromControls;
  proposedElementsById: IProposedElementsById;
  teamsById: ITeamsById;
  typesNeeded: ITypesNeeded;
}

interface IPropsFromDispatch {
  proposeElement: (elementId: number) => void;
  removeElement: (position: number) => void;
  showElementDialog: (elementId: number) => void;
  showElementType: (elementTypeId: number) => void;
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

interface IOwnProps {
  hideRef: React.RefObject<HTMLButtonElement>;
  hideSidebar: () => void;
  contentTitle: string;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

interface IState {
  page: number;
  elementForMenu: IElement | null;
}

class ElementList extends React.Component<Props> {
  public state: IState = { elementForMenu: null, page: 1 };

  public addElement = (element: IElement) => {
    createInteractionData({
      contentTitle: this.props.contentTitle,
      interactionId: "nba-fantasy-game:add-player:button",
      interactionText: `${element.first_name} ${
        element.second_name
      } ${this.props.t("elementList.addPlayer", "Add player")},`,
    });
    this.props.proposeElement(element.id);
    this.handleHideMenuForElement();
  };

  public removeElement = (position: number) => {
    this.props.removeElement(position);
    this.handleHideMenuForElement();
  };

  public handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: e.target.value,
    });
  };

  public handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      sort: e.target.value,
    });
  };

  public handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      search: e.target.value,
    });
  };

  public handleMaxCostChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      maxCost: Number(e.target.value),
    });
  };

  public handleShowMenuForElement = (element: IElement) =>
    this.setState({ elementForMenu: element });

  public handleHideMenuForElement = () => {
    this.setState({ elementForMenu: null });
  };

  public showDialog = (element: IElement) => {
    createInteractionData({
      contentTitle: this.props.contentTitle,
      interactionId: "nba-fantasy-game:view-information:button",
      interactionText: `${element.first_name} ${
        element.second_name
      } ${this.props.t("viewInformation", "View information")}`,
    });
    this.props.showElementDialog(element.id);
    this.handleHideMenuForElement();
  };

  public setPage = (page: number) => this.setState({ page });

  public componentDidMount() {
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: "all",
      sort: "now_cost",
      search: "",
      getUnavailable: false,
    });
  }

  public paginateAndGroup(elements: IElement[], pageSize: number) {
    const start = (this.state.page - 1) * pageSize;
    return {
      data: groupBy(elements.slice(start, start + pageSize), "element_type"),
      totalPages: Math.ceil(elements.length / pageSize),
    };
  }

  public renderMenu() {
    const element = this.state.elementForMenu;

    if (!element) {
      return null;
    }

    const { t } = this.props;
    const proposedElement = this.props.proposedElementsById[element.id];
    const roomInSquad = this.props.typesNeeded[element.element_type];

    let alert = null;
    if (!roomInSquad) {
      alert = (
        <Alert type="error" noRadius>
          {t(
            "elementList.errors.maxElementType",
            "You already have the maximum number of {{ position }}",
            {
              position:
                this.props.elementTypesById[element.element_type].plural_name,
            }
          )}
        </Alert>
      );
    } else if (proposedElement) {
      alert = (
        <Alert type="warning" noRadius>
          <Trans i18nKey="elementList.errors.alreadySelectedn">
            <strong>{{ playerName: element.web_name }}</strong> is already on
            your roster.
          </Trans>
        </Alert>
      );
    }

    return (
      <Dialog closeDialog={this.handleHideMenuForElement}>
        <Dialog.Header closeDialog={this.handleHideMenuForElement}>
          {`${element.first_name} ${element.second_name}`}
        </Dialog.Header>
        {alert && alert}

        <Dialog.Body>
          <PlayerActions>
            <DialogButtonItem>
              {proposedElement ? (
                <Button
                  onClick={() => this.removeElement(proposedElement.position)}
                  fullwidth="true"
                >
                  {t("elementList.removePlayer", "Remove player")}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    this.addElement(element);
                  }}
                  fullwidth="true"
                  disabled={Boolean(!roomInSquad)}
                >
                  {t("elementList.addPlayer", "Add player")}
                </Button>
              )}
            </DialogButtonItem>
            <DialogButtonItem>
              <Button
                onClick={() => this.showDialog(element)}
                fullwidth="true"
                variant="secondary"
              >
                {t("viewInformation", "View information")}
              </Button>
            </DialogButtonItem>
          </PlayerActions>
        </Dialog.Body>
      </Dialog>
    );
  }

  public render() {
    const {
      controls,
      currencyDivisor,
      elements,
      elementTypes,
      hideRef,
      hideSidebar,
      proposedElementsById,
      showElementType,
      teamsById,
      t,
    } = this.props;
    const { data, totalPages } = this.paginateAndGroup(elements.data, 30);
    return (
      <Box mb={4}>
        <ButtonWrap>
          <BoldLinkButton
            icon={<ControlArrowStart />}
            isBack={true}
            label={t("back", "Back")}
            onClick={hideSidebar}
            ref={hideRef}
            variant="light"
          />
        </ButtonWrap>
        <BoxWrap>
          <SectionHeader>
            <SectionHeading>
              {t("elementList.playerSelection", "Player Selection")}
            </SectionHeading>
          </SectionHeader>
          <Form onSubmit={(e) => e.preventDefault()}>
            <ElementFilter handleFilterChange={this.handleFilterChange} />
            <ElementSort handleSortChange={this.handleSortChange} />
            <FieldWrap>
              <SearchField
                id="search"
                name="search"
                label={t("elementList.search", "Search player list")}
                onChange={this.handleSearchChange}
                value={controls.search}
              />
            </FieldWrap>
            <FieldWrap>
              <SelectField
                id="maxCost"
                name="maxCost"
                label={t("elementList.maxCost.label", "Max cost")}
                value={controls.maxCost}
                onChange={this.handleMaxCostChange}
                required={false}
              >
                {range(elements.maxCost, elements.minCost - 1, -5).map(
                  (cost) => (
                    <option
                      key={cost}
                      value={cost}
                      aria-selected={controls.maxCost === Number(cost)}
                    >
                      {integerToMoney(cost, 10)}
                    </option>
                  )
                )}
              </SelectField>
            </FieldWrap>
          </Form>
          <ElementsShown>
            <Trans i18nKey="elementList.playersShown">
              <strong>{{ length: elements.data.length }}</strong> players shown
            </Trans>
          </ElementsShown>
          {elementTypes.map(
            (et) =>
              data[et.id] && (
                <Box mb={3} key={et.id}>
                  <ElementTable elementTypeId={et.id}>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <ElementListElement>
                          <ElementTypeButton
                            onClick={() => showElementType(et.id)}
                          >
                            {et.plural_name}
                          </ElementTypeButton>
                        </ElementListElement>
                        <ElementListPrice>
                          <Tooltip content={t("elementList.price", "Salary")}>
                            <TooltipLabel>$</TooltipLabel>
                          </Tooltip>
                        </ElementListPrice>
                        <ElementListStat>**</ElementListStat>
                      </tr>
                    </thead>
                    <tbody>
                      {data[et.id].map((e) => (
                        <ElementListRow
                          key={e.id}
                          element={e}
                          isProposed={proposedElementsById[e.id] ? true : false}
                          renderElementMenu={() =>
                            this.handleShowMenuForElement(e)
                          }
                          renderElementDialog={() => this.showDialog(e)}
                          team={teamsById[e.team]}
                          currencyDivisor={currencyDivisor}
                          sort={controls.sort}
                        />
                      ))}
                    </tbody>
                  </ElementTable>
                </Box>
              )
          )}
          {this.renderMenu()}
          <Paginator
            totalPages={totalPages}
            page={this.state.page}
            setPage={this.setPage}
          />
        </BoxWrap>
      </Box>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  currencyDivisor: 10,
  elementTypes: [...getElementTypes(state)].reverse(),
  elementTypesById: getElementTypesById(state),
  elements: getElementsFromControls(state),
  proposedElementsById: getProposedElementsById(state),
  teamsById: getTeamsById(state),
  typesNeeded: getTypesNeeded(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: IOwnProps
): IPropsFromDispatch => ({
  proposeElement: (elementId) => {
    dispatch(proposeElement(elementId));
    ownProps.hideSidebar();
  },
  removeElement: (position) => dispatch(removeElement(position)),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  showElementType: (elementTypeId) =>
    dispatch(updateElementTypeControl(elementTypeId)),
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ElementList)
);
