import { isSiteWithAd, TopScorersAdData } from "../utils/adverts";
import { getSite } from "../utils/sites";

const TopScorersAd = () => {
  const site = getSite();

  if (!isSiteWithAd(site, TopScorersAdData)) {
    return null;
  }

  const images = TopScorersAdData[site];
  return (
    <img
      srcSet={`${images.srcSetSmall} 1x, ${images.srcSetLarge} 2x`}
      src={`${images.srcSetSmall}`}
      alt={images.altText}
    />
  );
};

export default TopScorersAd;
