import styled from "styled-components";
import { isSiteWithAd, PageAdData } from "../../utils/adverts";
import { getSite } from "../../utils/sites";

const AdWrap = styled.div`
  width: 95%;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.space[1]};
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.space[6]};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 85%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 70%;
  }
`;

const PageAd = () => {
  const site = getSite();

  if (!isSiteWithAd(site, PageAdData)) {
    return null;
  }

  const adData = PageAdData[site];

  return (
    <AdWrap>
      <a target="_blank" rel="noreferrer" href={adData.href}>
        <picture>
          <source media="(max-width: 400px)" srcSet={`${adData.srcSetSmall}`} />
          <source media="(min-width: 401px)" srcSet={`${adData.srcSetLarge}`} />
          <img
            src={adData.srcSetSmall}
            srcSet={`${adData.srcSetSmall} 400w, ${adData.srcSetLarge} 1200w`}
            sizes="(max-width: 400px) 100vw, 1200px"
            alt={adData.altText}
            width="100%"
          />
        </picture>
      </a>
    </AdWrap>
  );
};

export default PageAd;
