import {
  Location,
  RouteComponentProps,
  Router,
  WindowLocation,
} from "@reach/router";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedComponent, useSelector } from "react-redux";
import { getLoginSuccess, getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
import { createCustomInteractionData } from "../utils/tealium";
import { Loading } from "./App";
import Copy from "./Copy";
import EntryHistory from "./EntryHistory";
import EntryUnsubscribe from "./EntryUnsubscribe";
import EntryUpdate from "./EntryUpdate";
import FixturesWrapper from "./FixturesWrapper";
import PlayerList from "./help/PlayerList";
import Home from "./Home";
import DreamTeam from "./icons/DreamTeam";
import { Main, Wrapper } from "./Layout";
import AdminClassic from "./leagues/AdminClassic";
import AutoJoin from "./leagues/AutoJoin";
import CreateClassic from "./leagues/CreateClassic";
import CreateJoin from "./leagues/CreateJoin";
import Invite from "./leagues/Invite";
import Join from "./leagues/Join";
import JoinPrivate from "./leagues/JoinPrivate";
import JoinPublic from "./leagues/JoinPublic";
import MyLeagues from "./leagues/MyLeagues";
import Renew from "./leagues/Renew";
import StandingsClassic from "./leagues/StandingsClassic";
import Login from "./Login";
import PlayerNews from "./news/playerNews";
import Logout from "./player/Logout";
import Register from "./player/Register";
import Prizes from "./Prizes";
import SquadSelection from "./squad/SquadSelection";
import Transfers from "./squad/Transfers";
import Statistics from "./stats/Statistics";
import EntryEvent from "./team/EntryEvent";
import MyTeam from "./team/MyTeam";
import TransfersHistory from "./TransfersHistory";

const Help = lazy(() => import("./help/Help"));
const Rules = lazy(() => import("./help/Rules"));
const Terms = lazy(() => import("./help/Terms"));
const Updates = lazy(() => import("./help/Updates"));

interface IRouteChangeProps {
  action: () => void;
}

interface IRouteChangeWorkerProps {
  location: WindowLocation;
}

type RouteChangeProps = IRouteChangeProps & IRouteChangeWorkerProps;

class OnRouteChangeWorker extends React.Component<RouteChangeProps> {
  componentDidUpdate(prevProps: RouteChangeProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action();
    }
  }

  render() {
    return null;
  }
}

const OnRouteChange: React.FC<IRouteChangeProps> = ({ action }) => (
  <Location>
    {({ location }) => (
      <OnRouteChangeWorker location={location} action={action} />
    )}
  </Location>
);

interface IProtectedRouteProps extends RouteComponentProps {
  as: React.ComponentType | ConnectedComponent<any, {}>;
  player: IPlayer | null;
}

const PlayerRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  player,
  ...rest
}) => {
  if (player && !player.entry) {
    return <Component {...rest} />;
  } else if (player && player.dirty) {
    return <Register />;
  } else {
    return (
      <Wrapper>
        <Main isWide>
          <Login />
        </Main>
      </Wrapper>
    );
  }
};

const EntryRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  player,
  ...rest
}) => {
  if (player && player.entry && !player.dirty) {
    return <Component {...rest} />;
  } else if (player && player.dirty) {
    return <Register />;
  } else {
    return (
      <Wrapper>
        <Main isWide>
          <Login />
        </Main>
      </Wrapper>
    );
  }
};

export const NotFound: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Copy>
          <h4>{t("routes.notFound.title", "Page not found")}</h4>
          <p>
            {t(
              "routes.notFound.text",
              "Sorry, but the page you were looking for can't be found."
            )}
          </p>
        </Copy>
      </Main>
    </Wrapper>
  );
};

const Routes: React.FC = () => {
  const player = useSelector(getPlayerData);
  const loginSuccess = useSelector(getLoginSuccess);
  const [hasPlayer, setHasPlayer] = useState(false);
  const [hasLoginSuccess, setHasLoginSuccess] = useState(false);

  useEffect(() => {
    if (player) {
      setHasPlayer(true);
    }

    if (loginSuccess) {
      setHasLoginSuccess(true);
    }

    if (hasPlayer && hasLoginSuccess) {
      const userData = {
        userState: "authenticated",
        userId: player?.nbaciamguid,
      };
      createCustomInteractionData({
        contentTitle: "home",
        interactionId: "fantasy:sign-in:success",
        userData: userData,
        ampEventName: "User Account: Login Success",
      });
      setHasLoginSuccess(false);
    }
  }, [loginSuccess, hasLoginSuccess, player, hasPlayer]);

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Home path="/" />
        <PlayerRoute
          as={SquadSelection}
          player={player}
          path="/squad-selection"
        />
        <EntryRoute as={MyTeam} player={player} path="/my-team" />
        <EntryRoute as={Transfers} player={player} path="/transfers" />
        <EntryRoute as={MyLeagues} player={player} path="/leagues" />
        <EntryRoute as={Renew} player={player} path="/leagues/renew" />
        <EntryRoute
          as={CreateJoin}
          player={player}
          path="/leagues/create-join"
        />
        <EntryRoute
          as={CreateClassic}
          player={player}
          path="/leagues/create/classic"
        />
        <EntryRoute as={Join} player={player} path="/leagues/join" />
        <EntryRoute
          as={JoinPrivate}
          player={player}
          path="/leagues/join/private"
        />
        <EntryRoute
          as={JoinPublic}
          player={player}
          path="/leagues/join/public"
        />
        <EntryRoute
          as={AdminClassic}
          player={player}
          path="/leagues/:leagueId/admin/c"
        />
        <EntryRoute
          as={Invite}
          player={player}
          path="/leagues/:leagueId/invite"
        />
        <StandingsClassic path="/leagues/:leagueId/standings/c" />
        <EntryRoute as={EntryUpdate} player={player} path="/entry-update" />
        <EntryHistory path="/entry/:entryId/history" />
        <EntryEvent path="/entry/:entryId/event/:eventId" />
        <TransfersHistory path="/entry/:entryId/transfers" />
        <DreamTeam path="/dream-team/" />
        <DreamTeam path="/dream-team/:eventId" />
        <Statistics path="/statistics" />
        <Statistics path="/statistics/:statName" />
        <FixturesWrapper path="/fixtures" />
        <FixturesWrapper path="/fixtures/:eventId" />
        <PlayerNews path="/player-news" />
        <Help path="/help" />
        <Rules path="/help/rules" />
        <Terms path="/help/terms" />
        <Updates path="/help/updates" />
        <Prizes path="/prizes" />
        <PlayerList path="/player-list" />
        <AutoJoin path="/leagues/auto-join/:code" />
        <Logout path="/player/logout" />
        <EntryUnsubscribe path="/entry/unsubscribe/:signature" />
        <NotFound default />
        <Register path="/player" />
      </Router>
      <OnRouteChange action={() => window.scrollTo(0, 0)} />
    </Suspense>
  );
};

export default Routes;
