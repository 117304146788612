import styled, { css } from "styled-components";
import { isSiteWithAd, HeaderAdData } from "../utils/adverts";
import { getSite } from "../utils/sites";

const StyledImg = styled.img<{ $site: string }>`
  position: absolute;
  right: 0.5rem;

  ${(props) =>
    props.$site === "main_fr" &&
    css`
      bottom: 8rem;
      width: 70px;
      height: 45px;
      @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
        right: 2rem;
        bottom: 6.5rem;
        width: 100px;
        height: 70px;
      }
      @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
        right: 6rem;
        bottom: 7rem;
        width: 140px;
        height: 105px;
      }
    `}

  ${(props) =>
    props.$site === "main_br" &&
    css`
      bottom: 8.8rem;
      width: 77px;
      height: 27px;
      @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
        right: 1rem;
        bottom: 8.3rem;
        width: 110px;
        height: 39px;
      }
      @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
        right: 2rem;
        bottom: 10rem;
        width: 190px;
        height: 67px;
      }
    `}
`;

const HeaderAd = () => {
  const site = getSite();

  if (!isSiteWithAd(site, HeaderAdData)) {
    return null;
  }

  const adData = HeaderAdData[site];

  return (
    <a target="_blank" rel="noreferrer" href={adData.href}>
      <StyledImg
        srcSet={`${adData.srcSetSmall} 1200w, ${adData.srcSetLarge} 2400w`}
        src={`${adData.srcSetSmall}`}
        alt={adData.altText}
        $site={site}
      />
    </a>
  );
};

export default HeaderAd;
