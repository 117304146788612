import { hideVisually, size } from "polished";
import React from "react";
import {
  WithTranslation,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getEntry,
  getPrivateClassicLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry, ILeagueEntry } from "../../core/store/entries/types";
import { leaveLeague } from "../../core/store/leagues/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import i18n from "../../i18n";
import { ReactComponent as BaseCog } from "../../img/icons/cog.svg";
import { isRTL } from "../../utils/locale";
import { createPageViewData } from "../../utils/tealium";
import Button from "../Button";
import ButtonLink from "../ButtonLink";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogManager from "../DialogManager";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import LinkButton from "../LinkButton";
import { BoldSubHeading } from "../SubHeading";
import Table from "../Table";
import { BoxWrap, VisuallyHidden } from "../Utils";
import Movement from "./Movement";

const ActionBar = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: flex;
  }
`;

const ActionBarItem = styled.li`
  padding: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 1;
  }
`;

const ActionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 50%;
    margin: 0 auto;
  }
`;

const MyLeaguesTable = styled(Table)`
  table-layout: fixed;

  th {
    padding: ${({ theme }) => theme.space[1]};

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      padding: ${({ theme }) => theme.space[2]};
    }
  }
  td {
    padding: ${({ theme }) => `${theme.space[2]} ${theme.space[1]}`};

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      padding: ${({ theme }) => theme.space[2]};
    }
  }
`;

const LeagueCol = styled.th`
  width: 40%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 36%;
  }
`;

const MovementCol = styled.th`
  width: 8%;
`;

const RankCol = styled.th`
  width: 24%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 18%;
  }
`;

const OptionsCol = styled.th`
  width: 10%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 20%;
  }
`;

const MenuCopy = styled.span`
  ${isRTL(i18n.language)
    ? css`
        margin-right: ${({ theme }) => theme.space[1]};
      `
    : css`
        margin-left: ${({ theme }) => theme.space[1]};
      `}

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${hideVisually}
  }
`;

const Cog = styled(BaseCog)`
  ${size(14)};
`;

interface IPropsFromState {
  entry: IEntry | null;
  player: ILoggedInPlayer;
  privateClassicLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  leave: (leagueId: number) => void;
}

type Props = WithTranslation & IPropsFromState & IPropsFromDispatch;

class MyLeagues extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchEntrySummary(this.props.player.entry);
    createPageViewData("leagues");
  }

  public render() {
    const {
      entry,
      leave,
      privateClassicLeagues,
      publicClassicLeagues,
      systemClassicLeagues,
      t,
    } = this.props;
    if (!entry) {
      return null;
    }
    return (
      <Wrapper>
        <Main>
          <VisuallyHidden as="div">
            <h2>{t("myLeagues.title", "My Leagues")}</h2>
          </VisuallyHidden>
          <BoxWrap>
            <Box pb={2}>
              <ActionBar>
                <ActionBarItem>
                  <ButtonLink to="create-join" fullwidth="true">
                    {t(
                      "myLeagues.createJoinButton",
                      "Create and join new leagues"
                    )}
                  </ButtonLink>
                </ActionBarItem>
                <ActionBarItem>
                  <ButtonLink fullwidth="true" to="renew">
                    {t("myLeagues.renewButton", "Renew your leagues")}
                  </ButtonLink>
                </ActionBarItem>
              </ActionBar>
            </Box>

            <Box pb={4}>
              <LeagueTypeTable
                title={t(
                  "myLeagues.tableTitles.privateClassic",
                  "Private classic leagues"
                )}
                leagues={privateClassicLeagues}
                leave={leave}
              />
            </Box>
            <Box pb={4}>
              <LeagueTypeTable
                title={t(
                  "myLeagues.tableTitles.publicClassic",
                  "Public classic leagues"
                )}
                leagues={publicClassicLeagues}
                leave={leave}
              />
            </Box>
            <Box pb={4}>
              <LeagueTypeTable
                title={t("myLeagues.tableTitles.global", "Global leagues")}
                leagues={systemClassicLeagues}
                leave={leave}
              />
            </Box>
          </BoxWrap>
        </Main>
        <Secondary />
      </Wrapper>
    );
  }
}

export { MyLeagues as MyLeaguesTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  const entry = player.entry;
  return {
    entry: getEntry(state, entry),
    player,
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entry),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entry),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entry),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: WithTranslation
): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  leave: (leagueId: number) => {
    if (
      window.confirm(
        ownProps.t(
          "myLeagues.confirmLeave",
          "Are you sure you want to leave this league?"
        )
      )
    ) {
      dispatch(leaveLeague(leagueId));
    }
  },
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyLeagues)
);

interface ILeagueTypeTableProps {
  leagues: ILeagueEntry[];
  leave: (leagueId: number) => void;
  title: React.ReactNode;
}

const LeagueTypeTable: React.FC<ILeagueTypeTableProps> = ({
  leagues,
  leave,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <BoldSubHeading>{title}</BoldSubHeading>
      <MyLeaguesTable>
        <thead>
          <tr>
            <LeagueCol>{t("myLeagues.th.league", "League")}</LeagueCol>
            <MovementCol>&nbsp;</MovementCol>
            <RankCol>{t("myLeagues.th.currentRank", "Current Rank")}</RankCol>
            <RankCol>{t("myLeagues.th.lastRank", "Last Rank")}</RankCol>
            <OptionsCol>&nbsp;</OptionsCol>
          </tr>
        </thead>
        <tbody>
          {leagues.map((l) => (
            <tr key={l.id}>
              <td>
                <Link to={`/leagues/${l.id}/standings/${l.scoring}`}>
                  {l.name}
                </Link>
              </td>
              <td>
                <Movement lastRank={l.entry_last_rank} rank={l.entry_rank} />
              </td>
              <td>{l.entry_rank ? l.entry_rank.toLocaleString() : "-"}</td>
              <td>
                {l.entry_last_rank ? l.entry_last_rank.toLocaleString() : "-"}
              </td>
              <td>
                <DialogManager
                  render={(showDialog, handleShow, handleHide) => (
                    <>
                      <LinkButton onClick={handleShow}>
                        <Cog />
                        <MenuCopy>
                          {t("myLeagues.optionsLink", "Options")}
                        </MenuCopy>
                      </LinkButton>
                      {showDialog && (
                        <LeagueMenuDialog
                          leagueEntry={l}
                          leave={leave}
                          handleHide={handleHide}
                        />
                      )}
                    </>
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </MyLeaguesTable>
    </div>
  );
};

interface ILeagueMenuDialogProps {
  handleHide: () => void;
  leagueEntry: ILeagueEntry;
  leave: (leagueId: number) => void;
}

const LeagueMenuDialog: React.FC<ILeagueMenuDialogProps> = ({
  handleHide,
  leagueEntry,
  leave,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>{leagueEntry.name}</Dialog.Header>
      <Dialog.Body isPadded={true}>
        <ActionList>
          <DialogButtonItem>
            <ButtonLink
              to={`/leagues/${leagueEntry.id}/standings/${leagueEntry.scoring}`}
              fullwidth="true"
            >
              {t("myLeagues.menuDialog.standings", "Standings")}
            </ButtonLink>
          </DialogButtonItem>
          {leagueEntry.entry_can_leave && (
            <DialogButtonItem>
              <Button
                onClick={() => {
                  leave(leagueEntry.id);
                  handleHide();
                }}
                fullwidth="true"
              >
                {t("myLeagues.menuDialog.leave", "Leave league")}
              </Button>
            </DialogButtonItem>
          )}
          {leagueEntry.entry_can_admin && (
            <DialogButtonItem>
              <ButtonLink
                to={`/leagues/${leagueEntry.id}/admin/${leagueEntry.scoring}`}
                fullwidth="true"
              >
                {t("myLeagues.menuDialog.admin", "Administer")}
              </ButtonLink>
            </DialogButtonItem>
          )}
          {leagueEntry.entry_can_invite && (
            <DialogButtonItem>
              <ButtonLink
                to={`/leagues/${leagueEntry.id}/invite`}
                fullwidth="true"
              >
                {t("myLeagues.menuDialog.invite", "Invite friends")}
              </ButtonLink>
            </DialogButtonItem>
          )}
        </ActionList>
      </Dialog.Body>
    </Dialog>
  );
};
