export interface DataLayerObject {
  [key: string]: any;
}

declare const window: Window & {
  playDataLayer: DataLayerObject;
};

export const pushToTagDataLayer = (obj: DataLayerObject) => {
  window.playDataLayer = window.playDataLayer || [];
  window.playDataLayer.push(obj);
};

interface IDigitalData {
  page: {
    pageInfo: {
      pageName: string;
      siteSection: string;
    };
    content: {
      categoryTitle: string;
      contentTitle: string;
      contentType: string;
    };
  };
  site: {
    propertyName: string;
    league: string;
    partner: string;
  };
  user: IUserData;
}

export const createDigitalData = (
  contentTitle: string,
  userData?: IUserData
): IDigitalData => {
  let digitalData: IDigitalData = {
    page: {
      pageInfo: {
        pageName: "Page View: Fantasy Game",
        siteSection: "Fantasy",
      },
      content: {
        categoryTitle: "Salary Cap",
        contentTitle: contentTitle,
        contentType: "2024-2025 fantasy salary cap",
      },
    },
    site: {
      propertyName: "nba:web",
      league: "nba",
      partner: "ISM Fantasy Games",
    },
    user: {},
  };

  if (userData) {
    digitalData.user = userData;
  }

  return digitalData;
};

interface IEventProperties {
  interactionCategory: string;
  interactionContent: string;
  eventTypes: {
    interaction: string;
    pageLoad: string;
  };
}

export const EVENT_PROPERTIES: IEventProperties = {
  interactionCategory: "2024-2025 fantasy salary cap",
  interactionContent: "Salary Cap",
  eventTypes: {
    interaction: "nba fantasy game interaction",
    pageLoad: "pageLoad",
  },
};

interface ICreateInteractionData {
  interactionText: string;
  interactionId: string;
  contentTitle: string;
  userData?: IUserData;
  ampEventName?: string;
}

interface IUserData {
  userId?: string | null | number | boolean | undefined;
  userState?: string;
}

interface ICreateCustomInteractionData {
  interactionId: string;
  ampEventName: string;
  errorDetail?: string[] | string | null;
  userData?: IUserData;
  contentTitle: string;
}

interface IInteractionData {
  eventType: string;
  eventData: {
    interactionCategory: string;
    interactionId: string;
    interactionContent: string;
    interactionText: string;
    eventDigitalData: any;
    ampEventName?: string;
  };
}

interface ICustomInteractionData {
  eventType: string;
  eventData: {
    customData: IUserData & {
      interactionIdentifier: string;
      interactionCategory: string;
      ampEventName: string;
      errorDetail?: string[] | string | null;
    };
    eventDigitalData: IDigitalData;
  };
}

export const createInteractionData = ({
  contentTitle,
  interactionId,
  interactionText,
  ampEventName,
}: ICreateInteractionData) => {
  const digitalData = createDigitalData(contentTitle);
  const dataObj: IInteractionData = {
    eventType: EVENT_PROPERTIES.eventTypes.interaction,
    eventData: {
      interactionCategory: EVENT_PROPERTIES.interactionCategory,
      interactionId: interactionId,
      interactionContent: EVENT_PROPERTIES.interactionContent,
      interactionText: interactionText,
      eventDigitalData: JSON.parse(JSON.stringify(digitalData)),
      ...(ampEventName && { ampEventName }),
    },
  };
  pushToTagDataLayer(dataObj);
};

export const createCustomInteractionData = ({
  interactionId,
  contentTitle,
  userData,
  ampEventName,
  errorDetail,
}: ICreateCustomInteractionData) => {
  const digitalData = createDigitalData(contentTitle || "", userData);
  const customDataObject: ICustomInteractionData = {
    eventType: EVENT_PROPERTIES.eventTypes.interaction,
    eventData: {
      customData: {
        interactionIdentifier: interactionId,
        interactionCategory: EVENT_PROPERTIES.interactionCategory,
        userId: digitalData.user.userId,
        userState: digitalData.user.userState,
        ampEventName: ampEventName,
        ...(errorDetail && { errorDetail }),
      },
      eventDigitalData: JSON.parse(JSON.stringify(digitalData)),
    },
  };
  pushToTagDataLayer(customDataObject);
};

export const createPageViewData = (contentTitle: string) => {
  const digitalData = createDigitalData(contentTitle);
  const dataObj = {
    eventType: "pageLoad",
    eventData: {
      customData: {},
      eventDigitalData: JSON.parse(JSON.stringify(digitalData)),
    },
  };
  pushToTagDataLayer(dataObj);
};
