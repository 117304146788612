import { range } from "lodash";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, SavingState, ThunkDispatch } from "../../core/store";
import {
  cancelActiveChip,
  cancelProposedChip,
  proposeAvailableChip,
  restoreCancelledChip,
} from "../../core/store/chips/actions";
import {
  getActiveChip,
  getActiveOrProposedAllChipName,
  getActiveOrProposedTeamChipName,
  getPotentialChips,
  teamChipsHaveChanged,
} from "../../core/store/chips/reducers";
import { IPotentialChip } from "../../core/store/chips/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry, IPickLight } from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
} from "../../core/store/events/reducers";
import { IEvent, IEventsById } from "../../core/store/events/types";
import { getFixturesForNextEventByTeam } from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { changeCaptain } from "../../core/store/my-team/actions";
import {
  getMyFormation,
  getMyPicksProposed,
  getMyTeamSavingState,
  hasMyTeamChanged,
  isMyTeamValid,
} from "../../core/store/my-team/reducers";
import {
  actionSubstitution,
  fetchMyTeam,
  saveMyTeam,
} from "../../core/store/my-team/thunks";
import { IPickProposed } from "../../core/store/my-team/types";
import { getPhases } from "../../core/store/phases/reducers";
import { IPhase } from "../../core/store/phases/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import {
  formatRawAsISO,
  formatRawAsLocalI18n,
} from "../../core/utils/datetime";
import { dateLocales } from "../../i18n";
import { ReactComponent as ArrowRight } from "../../img/icons/arrow-right.svg";
import { ChipName, getAllChipDetails } from "../../utils/chips";
import { getPrefixedEventShortName } from "../../utils/events";
import { createInteractionData, createPageViewData } from "../../utils/tealium";
import Alert from "../Alert";
import Button from "../Button";
import DeadlineBar from "../DeadlineBar";
import Dialog, { DialogButtonItem } from "../Dialog";
import Entry from "../Entry";
import { FixturesForElement } from "../FixtureForElement";
import Fixtures from "../Fixtures";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import Tooltip from "../Tooltip";
import DreamTeam from "../icons/DreamTeam";
import SaveBar from "../squad/SaveBar";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import BenchUnit from "./BenchUnit";
import ChipStatus from "./ChipStatus";
import MyTeamTable from "./MyTeamTable";
import PitchFormation from "./PitchFormation";
import TeamElementGroup from "./TeamElementGroup";
import { BenchElements } from "./styles";

const ChipSection = styled.div`
  margin: ${({ theme }) => theme.space[4]} 0 ${({ theme }) => theme.space[7]};
  padding: ${({ theme }) => theme.space[1]};
  border-radius: ${(props) => props.theme.radii[0]};
  list-style-type: none;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.blueDark},
    ${(props) => props.theme.colors.blue}
  );
`;

const Chip = styled.div`
  text-align: center;
`;

const ChipHeading = styled.h3`
  margin: ${({ theme }) => theme.space[2]} 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  margin ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
  }
`;

const ChipSeparator = styled.div`
  display: none;
  align-self: stretch;
  border-radius: ${(props) => props.theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.greyBlue};
  width: 2px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: block;
  }
`;

const ChipInfo = styled.p`
  flex: 1 1 0;
  text-align: left;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: ${({ theme }) => theme.space[1]};
  }
`;

const ButtonHeight = styled.div`
  flex: 1 1 0;
  align-self: stretch;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    align-self: center;
  }
`;

const ChipBorder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.space[4]};
  padding: ${(props) => `${props.theme.space[4]} ${props.theme.space[2]}`};
  border-radius: ${(props) => props.theme.radii[0]};
  background-color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
    padding: ${(props) => props.theme.space[2]};
  }
`;

const ChipLink = styled(Link)`
  position: relative;
  display: block;
  padding: 0.6rem 2.7rem 0.5rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.greyBlue};
  color: #333;
  text-transform: uppercase;

  :hover {
    text-decoration: none;
  }
`;

const ChipLinkArrow = styled(ArrowRight)`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.space[2]};
  transform: translateY(-50%);
  fill: currentColor;
  cursor: pointer;
`;

const renderPickValue =
  (initProps: {
    elementsById: IElementsById;
    fixturesForNextEventByTeam: IFixturesForEventByTeam;
    teamsById: ITeamsById;
  }) =>
  (pick: IPickLight) => {
    const element = initProps.elementsById[pick.element];
    return (
      <FixturesForElement
        myTeam={true}
        fixtures={initProps.fixturesForNextEventByTeam[element.team]}
        element={element}
        teamsById={initProps.teamsById}
      />
    );
  };

const PlayerActions = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 50%;
    margin: 0 auto;
  }
`;

const Note = styled.p`
  color: #6c6c6c;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
`;

interface IPropsFromState {
  activeChip: IPotentialChip | null;
  changed: boolean;
  chipInPlayName: string | null;
  chipAllPlayName: string | null;
  chips: IPotentialChip[];
  chipsChanged: boolean;
  entry: IEntry | null;
  elementsById: IElementsById;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  formation: string;
  nextEvent: IEvent | null;
  now: IEvent | null;
  picks: IPickProposed[];
  player: ILoggedInPlayer;
  savingState: SavingState;
  settings: ISettings;
  teamsById: ITeamsById;
  valid: boolean;
  eventsById: IEventsById;
  phases: IPhase[];
}

interface IPropsFromDispatch {
  cancelChip: (name: string) => void;
  deactivateChip: (name: string) => void;
  fetchEntrySummary: (entryId: number) => void;
  fetchMyTeam: () => void;
  makeCaptain: (elementId: number) => void;
  proposeChip: (name: string) => void;
  restoreChip: (name: string) => void;
  save: () => void;
  showElementDialog: (elementId: number) => void;
  substitute: (elementId: number) => void;
}

type Props = WithTranslation & IPropsFromState & IPropsFromDispatch;

interface IState {
  pickForMenu: IPickProposed | null;
}

class MyTeam extends React.Component<Props, IState> {
  public state: IState = {
    pickForMenu: null,
  };

  public handleShowMenuForPickElement = (element: number) => {
    const matches = this.props.picks.filter((p) => p.element === element);
    if (matches.length) {
      this.setState({ pickForMenu: matches[0] });
    }
  };

  public handleHideMenuForPick = () => {
    this.setState({ pickForMenu: null });
  };

  public renderDreamTeam = (pick: IPickLight) =>
    this.props.elementsById[pick.element].in_dreamteam ? (
      <Link to="/dream-team/">
        <DreamTeam />
      </Link>
    ) : null;

  public action = (type: "substitute" | "makeCaptain", element: number) => {
    this.handleHideMenuForPick();
    this.props[type](element);
    if (type === "makeCaptain") {
      createInteractionData({
        contentTitle: "edit lineup",
        interactionId: "nba-fantasy-game:make-captain:button",
        interactionText: this.props.t(
          "myTeam.menuDialog.makeCaptain",
          "Make Captain"
        ),
      });
    }
  };

  public showDialog = (element: IElement) => {
    this.props.showElementDialog(element.id);
    this.handleHideMenuForPick();
  };

  public renderMenu() {
    const pick = this.state.pickForMenu;
    if (!pick) {
      return null;
    }
    const element = this.props.elementsById[pick.element];
    const status = pick.subStatus;
    const startMax = this.props.settings.squad_squadplay;
    const activeChip = this.props.activeChip;
    const chipInPlayName = this.props.chipInPlayName;
    const showCaptButton =
      activeChip?.name === "phcapt"
        ? true
        : chipInPlayName === "phcapt"
        ? true
        : false;
    const t = this.props.t;
    return (
      <Dialog closeDialog={this.handleHideMenuForPick}>
        <Dialog.Header closeDialog={this.handleHideMenuForPick}>
          {`${element.first_name} ${element.second_name}`}
        </Dialog.Header>
        <Dialog.Body>
          <PlayerActions>
            {status.match(/^(target|)$/) && (
              <DialogButtonItem>
                <Button
                  onClick={() => this.action("substitute", pick.element)}
                  fullwidth="true"
                  variant="tertiary"
                >
                  {t("myTeam.menuDialog.switch", "Switch")}
                </Button>
              </DialogButtonItem>
            )}
            {status === "instigator" && (
              <DialogButtonItem>
                <Button
                  onClick={() => this.action("substitute", pick.element)}
                  fullwidth="true"
                >
                  {t("myTeam.menuDialog.cancel", "Cancel")}
                </Button>
              </DialogButtonItem>
            )}
            {!status &&
              pick.position <= startMax &&
              !pick.is_captain &&
              showCaptButton && (
                <DialogButtonItem>
                  <Button
                    onClick={() => this.action("makeCaptain", pick.element)}
                    fullwidth="true"
                  >
                    {t("myTeam.menuDialog.makeCaptain", "Make Captain")}
                  </Button>
                </DialogButtonItem>
              )}
            <DialogButtonItem>
              <Button
                onClick={() => this.showDialog(element)}
                fullwidth="true"
                variant="secondary"
              >
                {t("myTeam.menuDialog.viewInfo", "View Information")}
              </Button>
            </DialogButtonItem>
          </PlayerActions>
        </Dialog.Body>
      </Dialog>
    );
  }

  public componentDidMount() {
    this.props.fetchMyTeam();
    this.props.fetchEntrySummary(this.props.player.entry);
    createPageViewData("edit lineup");
  }

  public renderChipAction(c: IPotentialChip) {
    const {
      cancelChip,
      chipInPlayName,
      deactivateChip,
      entry,
      proposeChip,
      restoreChip,
      t,
      eventsById,
    } = this.props;
    switch (c.status_for_entry) {
      case "available":
      case "unavailable":
        return (
          <Button
            onClick={() => proposeChip(c.name)}
            disabled={Boolean(chipInPlayName)}
            variant="secondary"
            fullwidth="true"
          >
            {t("myTeam.play", "Play")}
          </Button>
        );
      case "proposed":
        return (
          <Button
            onClick={() => cancelChip(c.name)}
            fullwidth="true"
            variant="tertiary"
          >
            {t("myTeam.cancel", "Cancel")}
          </Button>
        );
      case "active":
        return (
          <Button onClick={() => deactivateChip(c.name)} fullwidth="true">
            {t("myTeam.cancel", "Cancel")}
          </Button>
        );
      case "cancelled":
        return (
          <Button
            onClick={() => restoreChip(c.name)}
            disabled={Boolean(chipInPlayName)}
            fullwidth="true"
            variant="tertiary"
          >
            {t("myTeam.restore", "Restore")}
          </Button>
        );
      case "played":
        if (!entry) {
          return (
            <>
              <div>{t("myTeam.played", "Played")}</div>
              <div>
                {getPrefixedEventShortName(
                  eventsById[c.played_by_entry[0]].name
                )}
              </div>
            </>
          );
        }
        return (
          <ChipLink to={`/entry/${entry.id}/event/${c.played_by_entry[0]}`}>
            <div>
              <div>{t("myTeam.played", "Played")}</div>
              <div>
                {getPrefixedEventShortName(
                  eventsById[c.played_by_entry[0]].name
                )}
              </div>
              <ChipLinkArrow />
            </div>
          </ChipLink>
        );

      default:
        return "";
    }
  }

  public render() {
    const {
      activeChip,
      changed,
      chipAllPlayName,
      chips,
      chipsChanged,
      elementsById,
      entry,
      fixturesForNextEventByTeam,
      formation,
      i18n,
      nextEvent,
      now,
      phases,
      picks,
      save,
      savingState,
      settings,
      t,
      teamsById,
      valid,
    } = this.props;

    const handleSaveTeam = () => {
      createInteractionData({
        contentTitle: "edit lineup",
        interactionId: "nba-fantasy-game:save-team:button",
        interactionText: t("myTeam.saveTeam", "Save Your Team"),
      });
      save();
    };

    if (!entry || !picks.length) {
      return null;
    }

    // Create a new function on each render as fixtures could have changed and
    // need to ensure a render of connected subcomponents
    const _renderPickValue = renderPickValue({
      elementsById,
      teamsById,
      fixturesForNextEventByTeam,
    });
    const benchHeading: Record<string, React.ReactNode> = {
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
    };
    const chipDetails = getAllChipDetails(t);
    const phaseCaptainChip = chips.find((c) => c.name === "phcapt");
    const activePhaseEvent = now ? now.id : 1;
    const activePhase = phases.find(
      (p) =>
        p.id > 1 &&
        p.start_event <= activePhaseEvent &&
        p.stop_event >= activePhaseEvent
    );
    const nextPhase = activePhase
      ? phases.find((ph) => ph.id === activePhase.id + 1)
      : null;
    let daysLeft = now && activePhase ? activePhase.stop_event - now.id : 0;
    if (now && daysLeft === 0 && nextPhase) {
      daysLeft = nextPhase.stop_event - now.id;
    }

    return (
      <>
        <Wrapper>
          <Main>
            {nextEvent && (
              <>
                <Box mb={2}>
                  <DeadlineBar
                    deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
                    deadlineLocal={formatRawAsLocalI18n(
                      nextEvent.deadline_time,
                      dateLocales[i18n.language]
                    )}
                    headingText={nextEvent.name}
                  />
                </Box>
              </>
            )}
            <Tabs centered>
              <TabPanel
                label={t("myTeam.pitchView", "Court View")}
                link="pitch"
              >
                <Box mb="4px">
                  <TeamElementGroup
                    title={t("myTeam.starters", "Starters")}
                    isHiddenTitle={true}
                  >
                    <PitchFormation
                      chipName={chipAllPlayName}
                      formation={formation}
                      picks={picks}
                      renderDreamTeam={this.renderDreamTeam}
                      renderElementMenu={this.handleShowMenuForPickElement}
                      renderPickValue={_renderPickValue}
                    />
                  </TeamElementGroup>
                </Box>
                <Box mb={4}>
                  <TeamElementGroup
                    title={t("myTeam.substitutesn", "Bench")}
                    isBench={true}
                  >
                    <BenchElements>
                      {range(
                        settings.squad_squadplay,
                        settings.squad_squadsize
                      ).map((i) => (
                        <BenchUnit
                          key={i}
                          chipName={chipAllPlayName}
                          heading={benchHeading[i]}
                          pick={picks[i]}
                          positionOnBench={i - settings.squad_squadplay + 1}
                          renderElementMenu={() =>
                            this.handleShowMenuForPickElement(picks[i].element)
                          }
                          renderDreamTeam={this.renderDreamTeam}
                          renderPickValue={_renderPickValue}
                        />
                      ))}
                    </BenchElements>
                  </TeamElementGroup>
                </Box>
              </TabPanel>
              <TabPanel label={t("myTeam.listView", "List View")} link="list">
                <Box mb={4} mx="-1px">
                  <MyTeamTable
                    title={t("myTeam.starters", "Starters")}
                    picks={picks.slice(0, settings.squad_squadplay)}
                    renderElementMenu={this.handleShowMenuForPickElement}
                  />
                  <MyTeamTable
                    title={t("myTeam.substitutesn", "Bench")}
                    picks={picks.slice(settings.squad_squadplay)}
                    renderElementMenu={this.handleShowMenuForPickElement}
                  />
                </Box>
              </TabPanel>
            </Tabs>
            {activeChip?.chip_type === "transfer" ? (
              <Box my={2}>
                <Alert type="info">
                  <p>
                    {t("myTeam.alerts.transferChip", "{{ chipName }} Active", {
                      chipName: chipDetails[activeChip.name as ChipName].name,
                    })}
                  </p>
                </Alert>
              </Box>
            ) : (
              phaseCaptainChip && (
                <>
                  <Alert type="info">
                    <p>
                      {t(
                        "myTeam.alerts.chipInfoUpdate.p1n",
                        "How you pick your captain has changed. Read more about what's new in the game via the"
                      )}{" "}
                      <StyledLink to="/help/updates">
                        {t("myTeam.alerts.chipInfoUpdate.p2n", "'Updates'")}
                      </StyledLink>{" "}
                      {t("myTeam.alerts.chipInfoUpdate.p3n", "section.")}
                    </p>
                  </Alert>
                  <ChipSection>
                    <Chip>
                      <Tooltip
                        content={
                          chipDetails[phaseCaptainChip.name as ChipName]
                            .description
                        }
                      >
                        <ChipHeading>
                          {chipDetails[phaseCaptainChip.name as ChipName].name}
                        </ChipHeading>
                      </Tooltip>
                      <ChipBorder>
                        <ChipInfo>
                          {t(
                            "myTeam.chip.CaptChip",
                            "Pick a captain to double their score. This chip can be used ONCE per gameweek."
                          )}
                        </ChipInfo>
                        {now && (
                          <>
                            <ChipSeparator />
                            <ChipStatus
                              status={phaseCaptainChip.status_for_entry}
                              daysLeft={daysLeft}
                            />
                          </>
                        )}
                        <ChipSeparator />
                        <ButtonHeight>
                          {this.renderChipAction(phaseCaptainChip)}
                        </ButtonHeight>
                      </ChipBorder>
                    </Chip>
                  </ChipSection>
                </>
              )
            )}
            {savingState === "saved" ? (
              <Box my={2}>
                <Alert type="success">
                  {t("myTeam.alerts.teamSaved", "Your team has been saved.")}
                </Alert>
              </Box>
            ) : (
              <>
                <SaveBar>
                  <Button
                    onClick={handleSaveTeam}
                    disabled={
                      (!changed && !chipsChanged) ||
                      !valid ||
                      savingState === "saving"
                    }
                    fullwidth="true"
                  >
                    {t("myTeam.saveTeam", "Save Your Team")}
                  </Button>
                </SaveBar>
                <Box mx={2} mb={4}>
                  <Note>
                    {t(
                      "myTeam.explain.p1",
                      "Only your starting 5 players will score points. Move players with a fixture into the starting 5."
                    )}{" "}
                    <br />
                    {t(
                      "myTeam.explain.p2",
                      "If your starter doesn't play, automatic substitutions will be made in the priority order selected below."
                    )}{" "}
                    <br />
                    {t(
                      "myTeam.explain.p3",
                      "To change your captain use the menu which appears when clicking on a player."
                    )}
                  </Note>
                </Box>
              </>
            )}
            {this.renderMenu()}
            <Fixtures />
          </Main>
          <Secondary>
            <Entry entryId={entry.id} />
          </Secondary>
        </Wrapper>
      </>
    );
  }
}

export { MyTeam as MyTeamTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  return {
    activeChip: getActiveChip(state),
    chipInPlayName: getActiveOrProposedTeamChipName(state),
    chipAllPlayName: getActiveOrProposedAllChipName(state),
    changed: hasMyTeamChanged(state),
    chips: getPotentialChips(state),
    chipsChanged: teamChipsHaveChanged(state),
    elementsById: getElementsById(state),
    entry: getEntry(state, player.entry),
    fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
    formation: getMyFormation(state),
    now: getCurrentEvent(state),
    nextEvent: getNextEvent(state),
    phases: getPhases(state),
    picks: getMyPicksProposed(state),
    player,
    savingState: getMyTeamSavingState(state),
    settings: getSettings(state) as ISettings,
    teamsById: getTeamsById(state),
    valid: isMyTeamValid(state),
    eventsById: getEventsById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  cancelChip: (name) => dispatch(cancelProposedChip(name)),
  deactivateChip: (name) => dispatch(cancelActiveChip(name)),
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchMyTeam: () => dispatch(fetchMyTeam()),
  makeCaptain: (elementId) => dispatch(changeCaptain(elementId)),
  proposeChip: (name) => dispatch(proposeAvailableChip(name)),
  restoreChip: (name) => dispatch(restoreCancelledChip(name)),
  save: () => dispatch(saveMyTeam()),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  substitute: (elementId) => dispatch(actionSubstitution(elementId)),
});
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyTeam)
);
