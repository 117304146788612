import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass";
import styled from "styled-components";
import i18n from "../i18n";
import { createPageViewData } from "../utils/tealium";
import Copy from "./Copy";
import { Main, Wrapper } from "./Layout";

const BoldParagraph = styled.p`
  font-weight: bold;
`;

const Prizes: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation("prizes");
  useEffect(() => {
    createPageViewData("prizes");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Main>
        <Copy>
          <h2>{t("prizes.pageTitle", "NBA Fantasy Prizes")}</h2>

          {i18n.language === "pt" ? (
            <Box my={4}>
              <p>
                {t("prizes.portugal.text1", "Prizes to be announced shortly.")}
              </p>
            </Box>
          ) : (
            <>
              <Box my={4}>
                <h2>
                  {t(
                    "prizes.season.countries.title1",
                    "Prizes Available for Residents of Austria, Belgium, Czechia, Denmark, Finland, Germany, Greece, Hungary, Ireland, Italy, Netherlands, Norway, Portugal, Serbia, Spain, Switzerland, and the United Kingdom"
                  )}
                </h2>
                <ul>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.text1",
                        "Prizes will be awarded to the top five (5) users from the above countries with the most points on a cumulative season long basis."
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.text2",
                        "One (1) Prize will be awarded per week (twenty-five (25) in total) to the Entrant with the most points in a given week."
                      )}
                    </p>
                  </li>
                </ul>
              </Box>
              <Box my={4}>
                <h2>{t("prizes.season.titleNN", "Season Long")}</h2>
                <h3>
                  {t(
                    "prizes.season.countries.grandPrize.title",
                    "Grand Prize - First (1st) Overall Ranking"
                  )}
                </h3>
                <p>
                  {t(
                    "prizes.season.countries.grandPrize.text1",
                    'There will be one (1) Grand Prize Winner ("Grand Prize Winner") awarded to the Entrant with the most accumulated points for the duration of the Contest.'
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.countries.grandPrize.text2",
                    'The "Grand Prize" consists of:'
                  )}
                </p>
                <ul>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.grandPrize.text3",
                        "Two (2) Economy class round trip airline tickets from the closest international airport to the Winners residence, to an airport in the USA, in a city where one of the NBA Finals games will be played (in each case, as determined and communicated by the Sponsor in its sole discretion)."
                      )}
                    </p>
                    <ul>
                      <li>
                        {t(
                          "prizes.season.countries.grandPrize.text4",
                          "Specific seat selection is the sole discretion of the Sponsor."
                        )}
                      </li>
                      <li>
                        {t(
                          "prizes.season.countries.grandPrize.text5",
                          "Exact destination city, dates and times of flights will be determined by the Sponsor in their sole discretion."
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.grandPrize.text6",
                        "One (1) hotel room for two (2) in the selected City for a total of three (3) nights at a 3-4 star hotel."
                      )}
                    </p>
                    <ul>
                      <li>
                        {t(
                          "prizes.season.countries.grandPrize.text7",
                          "Hotels, rooms, and dates to be chosen by the Sponsor in their sole discretion."
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.grandPrize.text8",
                        'Two (2) game tickets to one (1) NBA Finals game during the 2024-25 NBA season (the "Game").'
                      )}
                    </p>
                    <ul>
                      <li>
                        {t(
                          "prizes.season.countries.grandPrize.text9",
                          "Exact Game date i s the sole discretion of the Sponsor."
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.grandPrize.text10",
                        "An NBA merchandise box (consisting of a jersey, a hoodie, and a cap)."
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.grandPrize.text11",
                        "A First Place trophy, delivered in person or via courier to the winner's address, decided at the Sponsor’s sole discretion."
                      )}
                    </p>
                  </li>
                </ul>
                <p>
                  {t(
                    "prizes.season.countries.grandPrize.text12",
                    "Approximate total cumulative retail value of the Grand Prize is €4,000.00."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.countries.grandPrize.text13",
                    'Winner can nominate one (1) friend or family member to attend with them (the "Guest").'
                  )}
                </p>
              </Box>
              <Box my={4}>
                <h3>
                  {t(
                    "prizes.season.countries.additionalPrizes.title",
                    "Four (4) Additional Secondary Season Long Prizes - Second (2nd) through fifth (5th) Overall Ranking"
                  )}
                </h3>
                <p>
                  {t(
                    "prizes.season.countries.additionalPrizes.text1",
                    'These four (4) additional secondary prizes will be awarded to the Entrants with the second (2nd), third (3rd), fourth (4th), and fifth (5th) most cumulative points for the duration of the Contest (each a "Secondary Prize Winner").'
                  )}
                </p>
                <ul>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.additionalPrizes.text2",
                        "Second (2nd) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.additionalPrizes.text3",
                        "Third (3rd) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.additionalPrizes.text4",
                        "Fourth (4th) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.countries.additionalPrizes.text5",
                        "Fifth (5th) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                </ul>
              </Box>
              <Box my={4}>
                <h3>{t("prizes.season.countries.weekly.title1", "Weekly:")}</h3>
                <p>
                  {t(
                    "prizes.season.countries.weekly.text1",
                    'Twenty-five (25) additional prizes will be awarded to the Entrant with the most points in a given week (each a "Weekly Prize Winner"). One (1) Weekly Prize to be awarded per week x 25 total weeks Contest duration.'
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.countries.weekly.text2",
                    "The Weekly Prize will consist of a Digital Copy of NBA 2K25 and either an NBA League Pass code for season-long NBA League Pass Premium or an NBA Store gift card x €100.00 at"
                  )}{" "}
                  <a
                    href="https://nbastore.eu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://nbastore.eu
                  </a>{" "}
                  {t(
                    "prizes.season.countries.weekly.text3",
                    "(to be determined by the Sponsor in its sole discretion). Approximate total cumulative retail value of a Weekly Prize is €150.00."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.countries.weekly.text4",
                    "To be eligible to win the Weekly Prizes, users must have entered the game either before the start of the season or a minimum of 14 days prior to the start of the relevant gameweek period."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.countries.weekly.text5",
                    "A full list of gameweeks is available in the"
                  )}{" "}
                  <a
                    href="https://nbafantasy.nba.com/help/rules"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("prizes.season.countries.weekly.text6", "how to play")}
                  </a>{" "}
                  {t(
                    "prizes.season.countries.weekly.text7",
                    "section. Gameweeks are subject to change at the Sponsor’s sole discretion."
                  )}
                </p>
              </Box>
              <Box my={4}>
                <h2>{t("prizes.season.titleNN", "Season Long")}</h2>
                <h2>
                  {t(
                    "prizes.season.france.title1",
                    "Prizes Available for Residents of France"
                  )}
                </h2>

                <ul>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.text1",
                        "Prizes will be awarded to the top five (5) users from France with the most points on a cumulative season long basis."
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.text2",
                        "One (1) Prize will be awarded per week, (twenty-five (25) in total), to the Entrant from France with the most points in a given week."
                      )}
                    </p>
                  </li>
                </ul>
              </Box>
              <Box my={4}>
                <h3>
                  {t(
                    "prizes.season.france.grandPrize.title",
                    'Grand Prize - First (1st) Overall Ranking - France (the "France Grand Prize")'
                  )}
                </h3>
                <p>
                  {t(
                    "prizes.season.france.grandPrize.text1",
                    'There will be one (1) Grand Prize Winner ("France Grand Prize Winner") awarded to the Entrant with the most accumulated points for the duration of the Contest from France.'
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.france.grandPrize.text2",
                    "The France Grand Prize consists of:"
                  )}
                </p>
                <ul>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.grandPrize.text3",
                        "(2) Economy class round trip airline tickets from the closest international airport to the Winners residence, to an airport in the USA, in a city where one of the NBA Finals games will be played (in each case, as determined and communicated by the Sponsor in its sole discretion)."
                      )}
                    </p>
                    <ul>
                      <li>
                        {t(
                          "prizes.season.france.grandPrize.text4",
                          "Specific seat selection is the sole discretion of the Sponsor."
                        )}
                      </li>
                      <li>
                        {t(
                          "prizes.season.france.grandPrize.text5",
                          "Exact destination city, dates and times of flights will be determined by the Sponsor in their sole discretion."
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.grandPrize.text6",
                        "One (1) hotel room for two (2) in the selected City for a total of three (3) nights at a 3-4 star hotel."
                      )}
                    </p>
                    <ul>
                      <li>
                        {t(
                          "prizes.season.france.grandPrize.text7",
                          "Hotels, rooms, and dates to be chosen by the Sponsor in their sole discretion."
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.grandPrize.text8",
                        'Two (2) game tickets to one (1) NBA Finals game during the 2024-25 NBA season (the "Game").'
                      )}
                    </p>
                    <ul>
                      <li>
                        {t(
                          "prizes.season.france.grandPrize.text9",
                          "Exact Game date is the sole discretion of the Sponsor."
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.grandPrize.text10",
                        "An NBA merchandise box (consisting of a jersey, a hoodie, and a cap)."
                      )}
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.grandPrize.text11",
                        "A 'First Place' trophy, delivered in person or via courier to the winner's address, decided at the Sponsor’s sole discretion."
                      )}
                    </p>
                  </li>
                </ul>
                <p>
                  {t(
                    "prizes.season.france.grandPrize.text12",
                    "Approximate total cumulative retail value of the Grand Prize is €4,000.00."
                  )}
                </p>
              </Box>
              <Box my={4}>
                <h3>
                  {t(
                    "prizes.season.france.additionalPrizes.title",
                    "Four (4) Additional Secondary Season Long Prizes - Second (2nd) through fifth (5th) Overall Ranking"
                  )}
                </h3>
                <p>
                  {t(
                    "prizes.season.france.additionalPrizes.text1",
                    'These four (4) additional secondary prizes will be awarded to the Entrants with the second (2nd), third (3rd), fourth (4th), and fifth (5th) most cumulative points for the duration of the Contest (each a "France Secondary Prize Winner").'
                  )}
                </p>
                <ul>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.additionalPrizes.text2",
                        "Second (2nd) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.additionalPrizes.text3",
                        "Third (3rd) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.additionalPrizes.text4",
                        "Fourth (4th) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      {t(
                        "prizes.season.france.additionalPrizes.text5",
                        "Fifth (5th) place prize: NBA Store gift card x €100.00 at"
                      )}{" "}
                      <a
                        href="https://nbastore.eu"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nbastore.eu
                      </a>
                      .
                    </p>
                  </li>
                </ul>
              </Box>
              <Box my={4}>
                <h3>{t("prizes.season.france.weekly.title", "Weekly:")}</h3>
                <p>
                  {t(
                    "prizes.season.france.weekly.text1",
                    'Twenty-five (25) additional prizes will be awarded to the Entrant with the most points in a given week (each a "Weekly Prize Winner"). One (1) Weekly Prize to be awarded per week x 25 total weeks Contest duration.'
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.france.weekly.text2",
                    " The Weekly Prize will consist of a Digital Copy of NBA 2K25 and either an NBA League Pass code for season-long NBA League Pass Premium or an NBA Store gift card x €100.00 at"
                  )}{" "}
                  <a
                    href="https://nbastore.eu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://nbastore.eu
                  </a>{" "}
                  {t(
                    "prizes.season.france.weekly.text3",
                    "(to be determined by the Sponsor in its sole discretion). Approximate total cumulative retail value of a Weekly Prize is €150.00."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.france.weekly.text4",
                    "To be eligible to win the Weekly Prizes, users must have entered the game either before the start of the season or a minimum of 14 days prior to the start of the relevant gameweek period."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.france.weekly.text5",
                    "A full list of gameweeks is available in the"
                  )}{" "}
                  <a
                    href="https://nbafantasy.nba.com/help/rules"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("prizes.season.france.weekly.text6", "how to play")}
                  </a>{" "}
                  {t(
                    "prizes.season.france.weekly.text7",
                    "section. Gameweeks are subject to change at the Sponsor’s sole discretion."
                  )}
                </p>
              </Box>
              <Box my={4}>
                <BoldParagraph>
                  {t(
                    "prizes.season.general.title",
                    "The France Grand Prize Winner, the France Secondary Prize Winners and the France Weekly Winners are NOT eligible to win Prizes other than the Prizes available for residents of France."
                  )}
                </BoldParagraph>
                <p>
                  {t(
                    "prizes.season.general.para1.text1",
                    "All Winners and their Guests must comply with all venue, hotel and transportation carrier rules and regulations. Failure to do so may result in the forfeiture of the Prize element."
                  )}
                  {t(
                    "prizes.season.general.para1.text2",
                    "Exact seat locations at the venue are to be determined by the Sponsor in its sole discretion. The Prize is not negotiable or transferable."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.general.para2.text1",
                    "Any and all other costs and expenses associated with the acceptance and use of the Grand Prize and any other Prizes not specified herein as being awarded are the Winner's sole responsibility including, without limitation, applicable ground"
                  )}{" "}
                  {t(
                    "prizes.season.general.para2.text2",
                    "transportation, gratuities, merchandise, telephone calls, personal expenses of any nature for overnight layovers, meals and  beverages, service charges, in-flight meals and entertainment;"
                  )}{" "}
                  {t(
                    "prizes.season.general.para2.text3",
                    "costs incurred to and from the departure point for the Grand Prize to the residence of the Grand Prize Winner, obtaining sufficient personal travel insurance prior to departure, if desired;"
                  )}{" "}
                  {t(
                    "prizes.season.general.para2.text4",
                    "obtaining and carrying all necessary travel documentation, such as passports and visas and complying with entry, health, customs and immigrations regulations and requirements."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.general.para3.text1",
                    "The Winner and the Guest must both be at least 18 years old unless the Guest is the child or other person for whom the Winner has parental or legal guardian responsibility."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.general.para4.text1",
                    "The Winner and the Guest are responsible for obtaining and carrying all necessary travel documentation, such as passports and"
                  )}{" "}
                  {t(
                    "prizes.season.general.para4.text2",
                    "visas and complying with entry, health, customs and immigrations regulations and requirements."
                  )}
                </p>
                <p>
                  {t(
                    "prizes.season.general.para5.text1",
                    "In the event that the Winner or the Guest are unable to accept a Prize or are unable to travel or participate in any part of the Grand Prize for any reason, the Winner in question will forfeit"
                  )}{" "}
                  {t(
                    "prizes.season.general.para5.text2",
                    "the Prize in its entirety and, time permitting, the Prize will be awarded to an alternate Winner that will be randomly selected from any other eligible Entrants. The Sponsor retains the right to"
                  )}{" "}
                  {t(
                    "prizes.season.general.para5.text3",
                    "postpone or substitute the Prize with an item(s)/activity(s) of comparable or greater value as determined solely by the Sponsor"
                  )}{" "}
                  {t(
                    "prizes.season.general.para5.text4",
                    "and no additional compensation prize, cash or other alternative will be awarded to the Winner in such event."
                  )}
                </p>
              </Box>
            </>
          )}
        </Copy>
      </Main>
    </Wrapper>
  );
};
export default Prizes;
