import { RouteComponentProps } from "@reach/router";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { logout } from "../../core/store/player/thunks";
import {
  createInteractionData,
  createCustomInteractionData,
} from "../../utils/tealium";

const mapDispatch = {
  logout: () => logout(),
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = RouteComponentProps & PropsFromRedux & WithTranslation;

class Logout extends React.Component<Props> {
  public async componentDidMount() {
    const { t } = this.props;
    await this.props
      .logout()
      .then(() => {
        createInteractionData({
          contentTitle: "home",
          interactionId: "nba-fantasy-game:sign-out:cta",
          interactionText: t("navigation.signOut", "Sign out"),
        });
        window.location.replace("/");
      })
      .then(() => {
        const userData = {
          userState: "guest",
        };
        createCustomInteractionData({
          contentTitle: "home",
          interactionId: "fantasy:logout:success",
          userData: userData,
          ampEventName: "User Account: Logout Success",
        });
      })
      .catch(() => {});
  }

  public render() {
    return <div />;
  }
}

export default connector(withTranslation()(Logout));
